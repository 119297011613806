export const useTheme = async () => {
  const { currentWebsite, deferredCurrentWebsite } = useWebsite()

  const { getBrandTheme } = await useBrandsSettings()

  const currentBrandTheme = computed(() => getBrandTheme(currentWebsite.value))
  const deferredBrandTheme = computed(() =>
    getBrandTheme(deferredCurrentWebsite.value)
  )

  const isGiorgioArmaniTheme = computed(
    () => currentBrandTheme.value === 'giorgio-armani'
  )

  const isGiorgioArmaniThemeDeferred = computed(
    () => deferredBrandTheme.value === 'giorgio-armani'
  )

  return {
    currentBrandTheme,
    deferredBrandTheme,
    isGiorgioArmaniTheme,
    isGiorgioArmaniThemeDeferred,
  }
}
