export const SIDEBAR_IDS = {
  //my-account/profile/address.vue page sidebars
  addressNew: 'my-account-new-address',
  addressEdit: 'my-account-edit-address',
  bookAnAppointmentSidebar: 'bookAnAppointment',
  cartContactUsSideSlide: 'contact-sidebar',
  cartContactUsNeedHelpSidebarSlide: 'contact-need-help-sidebar',
  cartFeedbackAddToCart: 'add-to-cart-feedback',
  cartShippingSidebar: 'shipping-side-bar',
  cartSustainablePackagingSideSlide: 'sustainable-packaging-side-slide',
  cartTaxesSideBar: 'taxes-side-bar',
  cartWishlistSideSlide: 'cart-wishlist-sidebar',
  cartEditorialMessage: 'cart-editorial-message-sidebar',
  checkoutAddGiftMessageSidebar: 'gift-message-sidebar',
  // CheckoutAddressSidebarLog
  checkoutAddressSidebarLogNewAddress: 'logged-new-address',
  checkoutAddressSidebarLogEditAddress: 'logged-edit-address',
  // CheckoutPaymentSectionStep
  checkoutPaymentSectionStepAddressSidebarGuest: 'billing-guest-address',
  checkoutPaymentSectionStepAddressSidebarLogged: 'billing-logged-address',
  // CheckoutShipping
  checkoutShippingAddressSidebarGuest: 'shipping-guest-address',
  checkoutShippingAddressSidebarLogged: 'shipping-logged-addresses',
  checkoutPaymentCreditCardCVVSide: 'cvv-side',
  checkoutRecapSidebar: 'checkout-recap-sidebar',
  checkoutFeedbackSidebar: 'checkout-feedback-sidebar',
  checkoutFeedbackRecapSidebar: 'checkout-feedback-recap-sidebar',
  checkoutFeedbackPrivacyPolicySideSlide: 'checkout-privacy-policy-side-slide',
  editorialProductFocusSidebar: 'editorial-product-focus-sidebar',
  footerAsideLanguage: 'side-slide-footer-language',
  footerCountryNotFound: 'country-not-found-aside',
  giftDetailsSideSlide: 'promotion-gift-side-slide',
  checkoutWarehouseAvailabilitySidebar: 'warehouse-availability',
  checkoutStoreDetailsSidebar: 'store-details-side-slide',
  checkoutPickupStoreSidebar: 'pickup-store-side-slide',
  checkoutPickupStoreCantGoSidebar: 'pickup-store-cant-go-side-slide',

  headerSideCart: 'side-slide-mini-cart',
  loginResetPassword: 'password-reset-side',
  loginResetPasswordCustomerServiceSide: 'customer-service-password',
  loginSidebar: 'login-side-menu',
  myAccountEditPassword: 'sideslide-edit-password',
  myAccountEditPasswordSuccess: 'sideslide-edit-password-success',
  myAccountOrderReturnInfoSidebar: 'return-info-sidebar',
  myAccountProfileEditEmailSide: 'sideslide-edit-email',
  myAccountProfileEditNameSide: 'myAccount-profile-edit-name-side-slide',
  myAccountProfileEditNumberSide: 'myAccount-profile-edit-phone-side-slide',
  myAccountProfileRegionLanguageSide: 'sideslide-account-region-language',
  // the PdpColorSelector sidebar id is `${<static parent id>}-${<product id>}`, passed to the PdpEcommerceBlockColorSelector parent component
  pdpColorSelectorProductDetails: 'pdp-color-selector',
  pdpColorSelectorReserveInStore: 'reserve-in-store-color-selector',
  pdpColorSelectorUiQuickBuy: 'quick-buy-color-selector',
  pdpInfoReturnItem: 'sideslide-info-return-item',
  pdpInfoShippingItem: 'sideslide-info-shipping-item',
  pdpEcommerceBlockFitFinder: 'side-slide-fit-finder-selector',
  pdpEcommerceblockProductInfoAndDetailsSideSlide:
    'sideslide-info-and-details-id',
  pdpEcommerceBlockProductCareSideSlide: 'sideslide-product-care-id',
  pdpEcommerceBlockProductFocusSideSlide: 'sideslide-product-focus-id',
  pdpEcommerceBlockSizeSelector: 'side-slide-size-selector',
  pdpEcommerceBlockStoreDetails: 'storeDetails',
  pdp3dImage: 'product-image-3d',
  wrapperReserveInStore: 'findAndReserve',
  plpFitFinder: 'side-slide-fit-finder-selector-plp',
  plpFiltersAside: 'sidePlpFilters', //inside TemplatesPlp.vue
  //menu sidebars
  popupLayerListView: 'pop-up-layer-list-view',
  globalSearchModal: 'global-search-modal',

  thankYouPageRecapSidebar: 'side-slide-order-recap', // inside thank-you/[order].vue
  trackingOrder: 'tracking-order-side-slide',
  uiQuickBuyPopupSlide: 'quick-buy-pop-up-layer', //id for the global quick-buy
  uiQuickBuyPopupSlideWishlist: 'wishlist-quick-buy-pop-up-layer', //id for the wishlist quickbuy
  uiQuickBuyPopupSlideCheckout: 'checkout-quick-buy-pop-up-layer', //id for the checkout quickbuy
  wishlistLastItemAlertSideSlide: 'last-item-alert-side-slide',
  newsletterSidebar: 'newsletter-sidebar',
  editorialSidesheet: 'editorial-sidesheet',
  //Customer service
  cmsContentCustomerService: 'content-sideslide-customer-service',

  hotspotModalProduct: 'hotspot-modal-product',
} as const
