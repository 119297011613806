import { type ZodType } from 'zod'

export const useGASendEvent = <TEvent extends Record<string, any>>(
  schema: ZodType<TEvent>,
  event: TEvent,
  options?: {
    clearEcommerce?: boolean
    debug?: boolean
    waitForDatalayerSetup?: boolean // wait for datalayer_setup event
  }
) => {
  // Check if GTM-ID is present
  if (!useRuntimeConfig().public.scripts.googleTagManager.id) return
  if (!import.meta.client) return

  const { dataLayer } = useScriptGoogleTagManager()
  const isValid = schema.safeParse(event).success

  if (!isValid) {
    console.error(
      `Nuxt GA4 - Wrong validation: ${event?.event ?? ''}` +
        schema.safeParse(event).error
    )
    return
  }

  if (options?.debug) {
    console.log('Nuxt GA4 - debug event', {
      ...event,
    })
  }

  const sendEvent = () => {
    if (options?.clearEcommerce) dataLayer.push({ ecommerce: null })
    dataLayer.push(event)
  }

  const nuxt = useNuxtApp()
  if (options?.waitForDatalayerSetup) {
    //@ts-ignore
    const stopHook = nuxt.hook('app:after-page-view', () => {
      sendEvent()
      stopHook()
    })
  } else {
    sendEvent()
  }
}
