import type { Order } from '@commercelayer/sdk'
import z from 'zod'

type AlgoliaObject = z.infer<typeof algoliaProductsSchema>

type CartEventsPayload = {
  cart?: Order
  algoliaObjects?: Array<AlgoliaObject | null>
}

export const useGAEcommerceData = () => {
  const { mapData } = useMapGAEcommerceData()
  const cartEventsPayload = useGAEntity<CartEventsPayload | null | undefined>(
    'CART_GA_EVENTS_PAYLOAD'
  )

  const getEcommerceData = async (actionFromChat?: boolean, qty?: number) => {
    const cart = cartEventsPayload.value?.cart
    if (!cart || !cart?.line_items?.length) return
    return await mapData({
      cart,
      algoliaObjects: cartEventsPayload.value?.algoliaObjects ?? [],
      actionFromChat,
      qty,
    })
  }

  const setCartEventsPayload = (payload: CartEventsPayload) => {
    cartEventsPayload.value = {
      ...cartEventsPayload.value,
      ...payload,
    }
  }

  return { cartEventsPayload, getEcommerceData, setCartEventsPayload }
}
