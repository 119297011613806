import revive_payload_client_YTGlmQWWT5 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import session_client_eoKlRaLyT4 from "/opt/build/repo/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import _0_siteConfig_fWYXZjzQhM from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/opt/build/repo/packages/global/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/packages/global/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_rsWSYU43NF from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/packages/global/.nuxt/floating-vue.mjs";
import _01_plugin_i3gUYDezJc from "/opt/build/repo/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import titles_2g9zaaxwFs from "/opt/build/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_rjexdnU444 from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_2f4L554f7J from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import nuxt_plugin_PygVnJiOSv from "/opt/build/repo/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import _00_handleChunkErrors_dH4xWoa2S7 from "/opt/build/repo/packages/global/plugins/00.handleChunkErrors.ts";
import _01_configs_Q3TUbDWJ8g from "/opt/build/repo/packages/global/plugins/01.configs.ts";
import commercelayer_foCKWy86k8 from "/opt/build/repo/packages/global/plugins/commercelayer.ts";
import datadog_client_cfAAU11B0P from "/opt/build/repo/packages/global/plugins/datadog.client.ts";
import federated_event_bus_client_H56u8zTUrY from "/opt/build/repo/packages/global/plugins/federated-event-bus.client.ts";
import oneTrust_Yh6KviLCof from "/opt/build/repo/packages/global/plugins/oneTrust.ts";
import powerfront_client_rvb4A4ifm9 from "/opt/build/repo/packages/global/plugins/powerfront.client.ts";
import _03_define_MQNUriqats from "/opt/build/repo/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
import defaults_bUDeEQ1pwN from "/opt/build/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  session_client_eoKlRaLyT4,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_rsWSYU43NF,
  floating_vue_EIcJ7xiw0h,
  _01_plugin_i3gUYDezJc,
  titles_2g9zaaxwFs,
  siteConfig_rjexdnU444,
  inferSeoMetaPlugin_2f4L554f7J,
  nuxt_plugin_PygVnJiOSv,
  _00_handleChunkErrors_dH4xWoa2S7,
  _01_configs_Q3TUbDWJ8g,
  commercelayer_foCKWy86k8,
  datadog_client_cfAAU11B0P,
  federated_event_bus_client_H56u8zTUrY,
  oneTrust_Yh6KviLCof,
  powerfront_client_rvb4A4ifm9,
  _03_define_MQNUriqats,
  defaults_bUDeEQ1pwN
]