import type { GASetupDatalayerParamCart } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
export const useParamCart = (): GASetupDatalayerParamCart => {
  const { cartId, lineItems, totalAmoutOrder } = useCart()

  const cartProducts = lineItems.value.length
  const GA_METADATA = 'giorgio-armani'
  const EA_METADATA = 'emporio-armani'
  const EA7_METADATA = 'ea7'
  const AX_METADATA = 'armani-exchange'

  const firstBrand = computed(() => lineItems.value?.[0].metadata?.website)

  const axProducts = lineItems.value.filter(
    el => el.metadata?.website === AX_METADATA
  ).length
  const gaProducts = lineItems.value.filter(
    el => el.metadata?.website === GA_METADATA
  ).length
  const eaProducts = lineItems.value.filter(
    el => el.metadata?.website === EA_METADATA
  ).length
  const ea7Products = lineItems.value.filter(
    el => el.metadata?.website === EA7_METADATA
  ).length
  const isMonoBrand = computed(() =>
    lineItems.value.every(el => el.metadata?.website === firstBrand.value)
  )
  const allPrices = lineItems.value.map(el => {
    if (!el.total_amount_cents) return 0
    if (!el.discount_cents) return el.total_amount_cents
    return el.total_amount_cents
  })
  // Fallback price
  const cartTotalValue = allPrices.reduce((acc, el) => (acc += el), 0) / 100

  const formattedCartTotal =
    totalAmoutOrder.value?.toFixed(2) ?? cartTotalValue.toFixed(2)

  return {
    cartID: cartId.value ?? '',
    cart_products: cartProducts,
    cart_type: isMonoBrand.value ? 'mono_brand' : 'multi_brand',
    armani_exchange_cart: axProducts,
    client_service_cart: 0, //TODO: need backend service
    ea7_cart: ea7Products,
    emporio_armani_cart: eaProducts,
    giorgio_armani_cart: gaProducts,
    cartTotalValue: formattedCartTotal,
  }
}
