import { z } from 'zod'
import { itemSchema } from './'

export const addToWishlistSchema = z.object({
  event: z.string(),
  eventID: z.string(),
  ecommerce: z.object({
    view_type: z.string(),
    currency: z.string(),
    value: z.number(),
    items: z.array(itemSchema),
  }),
})

export const removeFromWishlistSchema = z.object({
  event: z.string(),
  eventID: z.string(),
  ecommerce: z.object({
    item_list_id: z.string(),
    item_list_name: z.string(),
    currency: z.string(),
    value: z.number(),
    items: z.array(itemSchema),
  }),
})
