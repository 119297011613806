export default defineAppConfig({
  website: 'global',
  currentLanguage: 'it',
  currentLanguageCountry: 'it-it',
  currentAlgoliaIndex: `${process.env.ALGOLIA_PREFIX}index_it_it`,
  currentVisenzeConfig: {
    app_key: '9d5c91b8f5784a660cb57c4c99e649e2',
    pdp_stl: '6085',
    pdp_vsr: '6086',
    vsearch_key: 'd7f581d654cec3d29c6102342298f46b',
    vsearch_id: '6226',
  },
  currentAlgoliaLookbookIndex: `${process.env.ALGOLIA_PREFIX}index_lookbook_it`,
  config: {
    country: 'it',
    languages: [
      {
        langcode: 'it',
        algolia_index: `${process.env.ALGOLIA_PREFIX}index_it_it`,
      },
      {
        langcode: 'en',
        algolia_index: `${process.env.ALGOLIA_PREFIX}index_it_en`,
      },
    ],
    is_shoppable: true,
    cl_market_code: 'IT',
  },
  currentMarketSettings: {
    region: '',
    max_MFCS: 2,
    max_cart: 10,
  } as {
    region: string
    max_MFCS: number
    max_cart: number
    klarnaBNPLMaxPriceCents?: number
    paypalBNPLMaxPriceCents?: number
    klarnaPlacementKey?: string
  },
})
