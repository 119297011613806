export const brandAcronymMap = {
  'giorgio-armani': 'GA',
  'emporio-armani': 'EA',
  ea7: 'E7',
  'armani-exchange': 'AX',
  'armani-casa': 'HD',
  'armani-fiori': 'AV',
  'armani-dolci': 'AV',
  'armani-beauty': 'AV',
  'armani-restaurant': 'FB',
  'armani-hotels-resorts': 'AV',
  'armani-silos': 'AS',
  'armani-clubs': 'AV',
  'echi-dal-mondo': 'GA',
}
