export const mapAmplienceSchemaToGAPageType = (
  amplienceSchema: string
): string => {
  switch (amplienceSchema) {
    case 'crossbrand-homepage-newest':
      return 'home'
    case 'brand-homepage':
      return 'subhome'
    case 'plp-page':
      return 'category'

    default:
      return amplienceSchema
  }
}
