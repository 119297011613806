export default defineNuxtRouteMiddleware((to, from) => {
  if (
    to.path !== from.path &&
    typeof window !== 'undefined' &&
    typeof window.RISKX !== 'undefined' &&
    import.meta.client
  ) {
    window.RISKX.go(to.path)
  }
})
