<template>
  <NuxtImg
    class="animate-fill-up"
    src="/images/Armani-Loader-A.svg"
    :alt="$ts('loading')"
  />
</template>

<style scoped lang="scss">
@keyframes fill-up {
  0% {
    opacity: 0%;
    clip-path: inset(100% 0 0 0);
  }
  50% {
    opacity: 100%;
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0% 0 0 0);
  }
}

.animate-fill-up {
  animation: fill-up 3.25s infinite ease-in;
}
</style>
