import {
  cookieBannerLabels,
  cookieBannerSchema,
  fitAnalyticsSchema,
  footerSchema,
  globalNavigationLabelsSchema,
  globalNavigationSchema,
  headerSchema,
  helpSchema,
  menuSchema,
  socials,
  socialSchema,
  storeLocatorSchema,
  subMenuSchema,
} from '@integration-layer/schemas/GAEvents/navigation'
import z from 'zod'
import snakeCase from 'lodash/snakeCase'

type GlobalNavigationLabels = z.infer<typeof globalNavigationLabelsSchema>
type Socials = z.infer<typeof socials>
type CookieBannerLabels = z.infer<typeof cookieBannerLabels>

export const useGANavigationEvents = () => {
  const debug = !!useRuntimeConfig().public.ga4.debug
  const dispatchGlobalNavigationEvent = (label: GlobalNavigationLabels) => {
    useGASendEvent(
      globalNavigationSchema,
      {
        event: 'GAevent',
        eventID: '005',
        eventCategory: 'global_navigation',
        eventAction: 'click_cta',
        eventLabel: label,
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchHeaderEvent = (element: string) => {
    useGASendEvent(
      headerSchema,
      {
        event: 'GAevent',
        eventID: '006',
        eventCategory: 'header',
        eventAction: 'click_cta',
        eventLabel: 'strips',
        eventElement01: snakeCase(element),
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchMenuEvent = (categories: string[]) => {
    useGASendEvent(
      menuSchema,
      {
        event: 'GAevent',
        eventID: '007',
        eventCategory: 'menu',
        eventAction: 'click_cta',
        eventLabel:
          snakeCase(categories?.[0]) === 'ea_7'
            ? 'EA7'
            : (snakeCase(categories?.[0]) ?? '-'),
        eventElement01: categories?.[1] ?? '-',
        eventElement02: categories?.[2] ?? '-',
        eventElement03: categories?.[3] ?? '-',
      },
      { debug }
    )
  }

  const dispatchStoreLocatorEvent = () => {
    useGASendEvent(
      storeLocatorSchema,
      {
        event: 'GAevent',
        eventID: '010',
        eventCategory: 'store_locator',
        eventAction: 'click_cta',
        eventLabel: 'Find a Boutique',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchSubMenuEvent = (label: string) => {
    useGASendEvent(
      subMenuSchema,
      {
        event: 'GAevent',
        eventID: '011',
        eventCategory: 'submenu',
        eventAction: 'click_cta',
        eventLabel: label,
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchFooterEvent = (category: string, label: string) => {
    useGASendEvent(
      footerSchema,
      {
        event: 'GAevent',
        eventID: '012',
        eventCategory: 'footer',
        eventAction: 'click_cta',
        eventLabel: category,
        eventElement01: label,
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchFitAnalyticsEvent = () => {
    useGASendEvent(
      fitAnalyticsSchema,
      {
        event: 'GAevent',
        eventID: '014',
        eventCategory: 'fitanalytics',
        eventAction: 'click_cta',
        eventLabel: 'start',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchCookieBannerEvent = (label: CookieBannerLabels) => {
    useGASendEvent(
      cookieBannerSchema,
      {
        event: 'GAevent',
        eventID: '016',
        eventCategory: 'banner_cookie',
        eventAction: 'click_cta',
        eventLabel: label,
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchSocialEvent = (label: string) => {
    useGASendEvent(
      socialSchema,
      {
        event: 'GAevent',
        eventID: '020',
        eventCategory: 'social',
        eventAction: 'click_cta',
        eventLabel: label as Socials,
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchHelpEvent = (
    transactionId: string,
    currency: string,
    amount: number
  ) => {
    useGASendEvent(
      helpSchema,
      {
        event: 'GAevent',
        eventID: '026',
        eventCategory: 'thank_you_page',
        eventAction: 'click_cta',
        eventLabel: 'contact_us',
        eventElement01: transactionId,
        eventElement02: currency,
        eventElement03: amount,
      },
      { debug }
    )
  }

  return {
    dispatchGlobalNavigationEvent,
    dispatchHeaderEvent,
    dispatchMenuEvent,
    dispatchStoreLocatorEvent,
    dispatchSubMenuEvent,
    dispatchFooterEvent,
    dispatchFitAnalyticsEvent,
    dispatchCookieBannerEvent,
    dispatchSocialEvent,
    dispatchHelpEvent,
  }
}
