export const extractCategories = (
  categories: Record<string, string[] | undefined> | undefined
) => {
  const defaultValues = { category1: '', category2: '', category4: '' }
  if (!categories) return defaultValues

  const _categories = Object.values(categories)?.pop()
  if (!_categories?.length) return defaultValues

  const numberOfCategoryLevels = _categories[0].split('>').length

  // Armani-casa has 3 levels of categories
  // A minimum of 3 levels are guaranteed for all brands
  if (numberOfCategoryLevels === 3) {
    const [_, category2, category1] = _categories[0]
      .split('>')
      .map(el => el.trim())
    return {
      category1,
      category2,
      category4: '',
    }
  }

  const categoryArray = _categories[0].split('>').map(el => el.trim())
  const category4 = categoryArray[1] ?? '' // Gender
  const category2 = categoryArray[categoryArray.length - 2] ?? '' // Subcategory first level
  const category1 = categoryArray[categoryArray.length - 1] ?? '' // Subcategory second level

  return {
    category1,
    category2,
    category4,
  }
}
