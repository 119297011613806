import {
  accountLoginSchema,
  accountRegistrationSchema,
  accountLogoutSchema,
  accountOAuthSchema,
  accountPopupSchema,
  accountMenuSchema,
} from '@integration-layer/schemas/GAEvents/accountEvents'

export const useGAAccountEvents = () => {
  const debug = !!useRuntimeConfig().public.ga4.debug

  const dispatchAccountLoginEvent = () => {
    useGASendEvent(
      accountLoginSchema,
      {
        event: 'GAevent',
        eventID: '001',
        eventCategory: 'login',
        eventAction: 'click_cta',
        eventLabel: '-',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug, waitForDatalayerSetup: true }
    )
  }

  const dispatchAccountRegistrationEvent = () => {
    useGASendEvent(
      accountRegistrationSchema,
      {
        event: 'GAevent',
        eventID: '002',
        eventCategory: 'registration',
        eventAction: 'view_element',
        eventLabel: 'popup',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchAccountLogoutEvent = () => {
    useGASendEvent(
      accountLogoutSchema,
      {
        event: 'GAevent',
        eventID: '003',
        eventCategory: 'logout',
        eventAction: 'click_cta',
        eventLabel: '-',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchAccountOAuthEvent = (socialIcon: string) => {
    useGASendEvent(
      accountOAuthSchema,
      {
        event: 'GAevent',
        eventID: '021',
        eventCategory: 'oauth',
        eventAction: 'click_cta',
        eventLabel: socialIcon,
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchAccountMenuEvent = (accountIcon: string) => {
    useGASendEvent(
      accountMenuSchema,
      {
        event: 'GAevent',
        eventID: '022',
        eventCategory: 'account_menu',
        eventAction: 'click_cta',
        eventLabel: accountIcon ?? '',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchAccountPopupLoginEvent = (loginIcon: string) => {
    useGASendEvent(
      accountPopupSchema,
      {
        event: 'GAevent',
        eventID: '023',
        eventCategory: 'popup_login',
        eventAction: 'click_cta',
        eventLabel: loginIcon ?? '',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  return {
    dispatchAccountLoginEvent,
    dispatchAccountRegistrationEvent,
    dispatchAccountLogoutEvent,
    dispatchAccountOAuthEvent,
    dispatchAccountMenuEvent,
    dispatchAccountPopupLoginEvent,
  }
}
