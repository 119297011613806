import type { PageHierarchyNode } from '@integration-layer/ampliance/schemas/page-hierarchy-node-schema.localized'
import type { H3Event, EventHandlerRequest } from 'h3'
import { setHeader } from 'h3'

async function addCacheHeaders(
  event: H3Event<EventHandlerRequest>,
  vary?: string
) {
  if (import.meta.server) {
    const ONE_YEAR_IN_SECONDS = 3600 * 24 * 365
    const CACHE_AGE = 3600 * 12
    setHeader(event, 'Cache-Control', 'public, max-age=0, must-revalidate')
    setHeader(event, 'Netlify-Vary', vary ?? 'query')
    setHeader(
      event,
      'Netlify-CDN-Cache-Control',
      `public, max-age=${CACHE_AGE}, stale-while-revalidate=${ONE_YEAR_IN_SECONDS}, durable`
    )
  }
}

export default defineNuxtRouteMiddleware(async to => {
  if (useRuntimeConfig().public.isFederated) return
  const app = useNuxtApp()
  if (to.name === 'slug') {
    // at this point in time, edge functions didn't find any redirect
    // netlify didnt find any static file
    // so we have a impossible to be present slug page (since we always want localized-slug)
    throw createError({
      statusCode: 404,
    })
  }
  if (
    !(
      (to.name as string).startsWith('localized-slug') ||
      (to.name as string) === 'localized-index'
    )
  ) {
    // we are navigating away from an amplience page, we should reset page type
    app.payload['amplience_page_type'] = null
    app.payload['is_product'] = false
    app.payload['header_config'] = {
      megalogo: false,
      transparency: false,
    }
    return
  }

  const getIsProduct = () => {
    const slug = removeLastEmptyParam(to.params.slug)
    const productSlug = slug?.at(-1)

    if (!productSlug) return false
    const code = (productSlug as string).split('-cod-')[1]
    if (!code) return false
    const segments = code.split('-')
    const isValid = segments.length === 3
    return isValid
  }

  const isProduct = getIsProduct()
  if (isProduct) {
    app.payload['amplience_page_type'] = null
    app.payload['is_product'] = true
    app.payload['header_config'] = {
      megalogo: false,
      transparency: false,
    }
    const event = useRequestEvent()
    await addCacheHeaders(event!, 'cookie=show_confirm_country, query')
    if (import.meta.server) {
      const userSession = await app.runWithContext(useUserSession)
      try {
        await userSession.clear()
      } catch {
        await $fetch('/api/_auth/session', { method: 'DELETE' })
        // @ts-expect-error
        userSession.session.value = {}
      }
      app.payload.isCached = true
    }
    return
  }

  app.payload['is_product'] = false

  const getDeliveryKey = () => {
    const segments = to.path.split('/').slice(1)
    const [loc, country] = segments[0].split('-')
    segments[0] = `${loc?.toLowerCase()}-${country?.toUpperCase()}`
    return segments.join('/')
  }
  const deliveryKey = getDeliveryKey()
  const vse = to.query.vse! as string

  const locale = to.path.split('/')[1]
  const url = `/api/cms/getByKey?locale=${locale}&id=${deliveryKey}&type=key&vse=${vse ?? ''}`

  const { data, error } = await useFetch<PageHierarchyNode>(url, {
    key: url,
  })

  // we need to infer page type from schema
  if (error.value) {
    if (import.meta.server) {
      throw createError({
        statusCode: 404,
        fatal: true,
      })
    }
    if (import.meta.client) {
      app.payload['middleware_error'] = true
    }
  }
  // else, set page type
  app.payload['amplience_page_type'] = data.value?.schema

  /*
    Header config
  */
  let hasPageTransparentHeader = false
  let hasPageMegalogo = false
  const pagesWithTransparentHeader = ['https://armani.com/brand-homepage']

  if (data.value?.schema === 'https://armani.com/plp-page') {
    // @ts-ignore
    hasPageTransparentHeader = !!data.value?.content?.at(0)?.bannerImage
  } else if (data.value?.schema === 'https://armani.com/editorial-page') {
    // @ts-ignore
    hasPageTransparentHeader =
      // @ts-ignore
      data.value?.content?.at(0)?.headerTransparent ?? true
  } else {
    hasPageTransparentHeader = pagesWithTransparentHeader.includes(
      data.value?.schema ?? ''
    )
  }

  if (hasPageTransparentHeader) {
    // @ts-ignore
    hasPageMegalogo = !!data.value?.content?.at(0)?.showMegalogo
  }

  if (
    data.value?.schema === 'https://armani.com/crossbrand-homepage-newest' &&
    // @ts-ignore
    data.value?.content?.[0]?.editorialValuesTop
  ) {
    app.payload['hp_has_editorial_values_top'] = true
  }

  app.payload['header_config'] = {
    megalogo: hasPageMegalogo,
    transparency: hasPageTransparentHeader,
    // @ts-ignore
    color_transparency: data.value?.content?.at(0)?.headerTextColor,
  }

  // add cache headers
  const event = useRequestEvent()
  const vary =
    data.value?.schema === 'https://armani.com/plp-page'
      ? `cookie=${useAppConfig().website}-grid-layout-cookies|show_confirm_country,query`
      : 'cookie=show_confirm_country,query'
  // add cache headers
  await addCacheHeaders(event!, vary)
  if (import.meta.server) {
    const userSession = await app.runWithContext(useUserSession)
    try {
      await userSession.clear()
    } catch {
      await $fetch('/api/_auth/session', { method: 'DELETE' })
      // @ts-expect-error
      userSession.session.value = {}
    }
    app.payload.isCached = true
  }
})
