import type { Auth, Token } from '@integration-layer/configs/commercelayer'
import { isTokenValid } from '@integration-layer/configs/commercelayer'
import { StorageSerializers } from '@vueuse/core'

export const useClCustomerAuth = (email: string): Auth => {
  const config = useRuntimeConfig()
  const configs = useConfigs()
  const organization = config.public.commercelayer.organization
  const market = configs.value.cl_market_code
  const country = configs.value.country

  const key = `${market}_${email}`

  const inMemoryTokenPromise: Record<string, Promise<Token>> = {}

  const storage = process.client
    ? useLocalStorage<Token>(`commercelayer:customerToken:${key}`, null, {
        serializer: StorageSerializers.object,
      })
    : null

  const getToken = async () => {
    const tokenPromise = inMemoryTokenPromise[key]
    const token = (await tokenPromise) ?? storage?.value
    if (token && isTokenValid(token)) return token.accessToken

    return await refreshToken()
  }

  const refreshToken = async () => {
    const tokenPromise = $fetch<Token>(`/api/cl/getCustomerToken`, {
      method: 'POST',
      body: {
        country,
      },
    })
    // @ts-ignore
    inMemoryTokenPromise[key] = tokenPromise
    const newToken = await tokenPromise

    if (storage) {
      // @ts-ignore
      storage.value = newToken
    }

    return newToken.accessToken
  }

  return {
    organization,
    getToken,
    refreshToken,
  }
}
