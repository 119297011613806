import { withTrailingSlash, withoutTrailingSlash, resolveURL } from 'ufo'
import { useRequestURL, useError, useSiteConfig } from '#imports'

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.prerender || import.meta.client) return

  const siteConfig = useSiteConfig()
  const error = useError()
  const url = useRequestURL()

  const originalUrl = url.href

  if (
    url.pathname.endsWith('.css') ||
    url.pathname.endsWith('.js') ||
    url.pathname.endsWith('.html')
  ) {
    return
  }
  let canonicalUrl =
    siteConfig.url && !import.meta.dev
      ? resolveURL(siteConfig.url, url.pathname, url.search, url.hash)
      : originalUrl

  if (siteConfig.trailingSlash) {
    canonicalUrl = siteConfig.trailingSlash
      ? withTrailingSlash(canonicalUrl, true)
      : withoutTrailingSlash(canonicalUrl, true)
  }

  if (canonicalUrl !== originalUrl && !error.value) {
    return navigateTo(canonicalUrl, { redirectCode: 301, external: true })
  }
})
