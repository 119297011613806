import snakeCase from 'lodash/snakeCase'
export const useGAFormattedBrands = () => {
  const { currentWebsite: currentWebsiteToFormat } = useWebsite()

  let formattedBrand = 'armani_group'

  switch (currentWebsiteToFormat.value) {
    case 'ea7':
      formattedBrand = 'EA7'
      break
    case undefined:
    case 'global':
      formattedBrand = 'armani_group'
      break

    default:
      formattedBrand = snakeCase(currentWebsiteToFormat.value)
      break
  }
  return formattedBrand
}
