import z from 'zod'

export const internalSearchButtonSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('013'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('-'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const internalSearchBrandSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('029'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('search_brand'),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.literal('-'),
})

export const internalSearchImageSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('030'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('search_image'),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const internalSearchFilterSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('031'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('internal_filter'),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.string(),
})

export const internalSearchExpandedSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('032'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('internal_expansion'),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.literal('-'),
})
