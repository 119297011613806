export default defineNuxtRouteMiddleware((to, from) => {
  const { clearAllSnackbars } = useHeaderSnackbars()
  if (
    from.path !== to.path &&
    from.name !== 'localized-checkout-adyen' &&
    from.name !== 'localized-checkout-paypal'
  ) {
    clearAllSnackbars()
  }
})
