import { z } from 'zod'

export const wishlistStorageItemSchema = z.object({
  // the product code WITHOUT the size
  productCode: z.string(),
  size: z
    .object({
      // the sku code WITH the size
      skuCode: z.string(),
      value: z.string(),
      country: z.string(),
    })
    .optional(),
  website: z.string(),
  notifyMe: z.boolean().optional(),
  country: z.string(),
})

export const wishlistResponseSchema = z.object({
  items: z.array(
    z.object({
      'salesforce-id': z.string(),
      wishlists: z.array(wishlistStorageItemSchema),
    })
  ),
})

export const createUpdateWishlistSchema = z.object({
  wishlists: z.array(wishlistStorageItemSchema),
})
