import type { AsyncDataOptions } from '#app'
import type { GASetupDatalayerParamWishlist } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import type { WishlistView } from '@integration-layer/composables/useGAWishlistEvents'
import type {
  WishlistItem,
  WishlistStorageItem,
} from '@integration-layer/utils/wishlist'

type useWishlistParams = Required<Pick<AsyncDataOptions<unknown>, 'dedupe'>>
type AddOrRemoveItemParams = {
  item: Omit<WishlistItem, 'notifyMe'>
  view?: WishlistView
  forceRefresh?: boolean
}

export const useWishlist = async (
  { dedupe }: useWishlistParams = { dedupe: 'cancel' }
) => {
  const configs = useConfigs()
  const appConfig = useAppConfig()
  const language = appConfig.currentLanguage
  const algoliaIndex = appConfig.currentAlgoliaIndex
  const { $cl } = useNuxtApp()
  const { isLogged } = useLogin()
  const { addNotification, deleteNotification } = useWishlistFeedback()
  const { dispatchAddToWishlistEvent, dispatchRemoveFromWishlistEvent } =
    useGAWishlistEvents()

  /* The wishlistMemo global state is used to make optimistic updates to wishlist data on client side.
  It is needed as a workaround for the wishlist api that is slow and doesn't return a response;
  when navigating to the wishlist page, just after adding or removing an item, sometimes you didn't find it updated as expected.
  This logic improves the reaction time of wishlist's actions and solve the problem explained above,
  but it's not perfect and could lead to unexpected behaviour */

  const wishlistMemo = useState<WishlistItem[]>('WISHLIST_MEMO', () => [])
  const deletedWishlistItems = useState<WishlistItem[]>(
    'DELETED_WISHLIST_ITEMS',
    () => []
  )

  const firstLoad = useState('FIRST_LOAD', () => true)
  const forceReload = useState('FORCE_RELOAD', () => false)
  const isWishlistLoading = useState('IS_WISHLIST_LOADING', () => true)
  const isDeletingAll = useState('IS_DELETING_ALL', () => false)

  const wishlistStorage = isLogged.value
    ? authWishlistStorage
    : guestWishlistStorage

  const fetchProductsData = async (storedItems: WishlistStorageItem[]) => {
    const productCodes = storedItems.map(({ productCode }) => productCode)
    const algoliaProducts = productCodes.length
      ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
          query: {
            mfcList: productCodes.join(','),
          },
        })
      : []

    const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)
    const skuCodes = filteredAlgoliaProducts.reduce<string[]>(
      (acc, { size }) => {
        const skus = size.flatMap(({ SKU }) => SKU ?? [])
        acc.push(...skus)
        return acc
      },
      []
    )

    //Api call inside the ternary to not trigger it beforehand if skuCodes.length is 0
    const skus = skuCodes.length
      ? (
          await Promise.all(
            chunk(skuCodes, 24).map(skuCodesChunk =>
              $cl.skus.list({
                include: [
                  'prices',
                  'stock_items',
                  'stock_items.reserved_stock',
                ],
                filters: {
                  code_in: skuCodesChunk?.join() ?? '',
                },
                pageSize: 24,
              })
            )
          )
        ).flat()
      : []

    return mapWishlistItems(
      storedItems,
      filteredAlgoliaProducts,
      skus,
      configs.value.country,
      language
    )
  }

  const getWishlist = async () => {
    if (!firstLoad.value && !forceReload.value) {
      return {
        wishlist: mapStorageItems(wishlistMemo.value, configs.value.country),
        wishlistItems: wishlistMemo.value,
      }
    }

    isWishlistLoading.value = true
    firstLoad.value = false
    const wishlist = await wishlistStorage.get()
    const wishlistItems = await fetchProductsData(wishlist)
    wishlistMemo.value = [...wishlistItems]
    isWishlistLoading.value = false
    return {
      wishlist,
      wishlistItems,
    }
  }

  // GA Datalayer event
  const wishlistEntity = useGAEntity<GASetupDatalayerParamWishlist>(
    'GA_DL_SETUP_WISHLIST'
  )

  const { data, refresh } = await useAsyncData('WISHLIST_DATA', getWishlist, {
    server: false,
    dedupe,
    default: () => ({ wishlist: [], wishlistItems: [] }),
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })

  const rawWishlist = computed(() => data.value.wishlist)
  const wishlistItems = computed(() => data.value.wishlistItems)
  const wishlistItemsByBrand = computed(() =>
    sortItemsByBrand(wishlistItems.value, item => item.productBrand)
  )

  const nProducts = computed(() => wishlistItems.value.length)
  const isWishlistEmpty = computed(() => !nProducts.value)
  const wishlistProductCodes = computed(() =>
    rawWishlist.value.reduce<{ [key: string]: boolean }>(
      (acc, { productCode }) => {
        if (!acc[productCode]) acc[productCode] = true
        return acc
      },
      {}
    )
  )

  const mergeWishlists = async () => {
    if (!isLogged.value) return
    const guestWishlist = await guestWishlistStorage.get()
    const itemsToAdd = diffWishlists(guestWishlist, rawWishlist.value)
    if (!itemsToAdd.length) return
    const wishlistItems = await fetchProductsData(itemsToAdd)
    await addItems(wishlistItems)
    await guestWishlistStorage.delete()
  }

  const updateNotifyMe = useDebounceFn(
    async (productCode: string, notifyMeLast: boolean) => {
      const storageItem = rawWishlist.value.find(
        item => item.size?.skuCode === productCode
      )
      const wishlistItem = wishlistMemo.value.find(
        item => item.productSku === productCode
      )
      if (!storageItem || !wishlistItem) return
      const wishlistBeforeUpdate = [...wishlistMemo.value]
      try {
        storageItem.notifyMe = notifyMeLast
        // optimistic update
        wishlistItem.notifyMe = notifyMeLast
        await wishlistStorage.createOrUpdate([...rawWishlist.value])
      } catch (e) {
        console.error(e)
        wishlistMemo.value = [...wishlistBeforeUpdate]
      } finally {
        await refresh({ dedupe: 'cancel' })
      }
    },
    250
  )

  const addItems = async (items: WishlistItem[]) => {
    if (!items.length) return
    // saving wishlist state before updating it, we will need it if adding to db fails
    const wishlistBeforeUpdate = [...wishlistMemo.value]
    try {
      const itemsWithNotifyMe = items.map(item => ({
        ...item,
        ...(item.productSku ? { notifyMe: true } : {}),
        productBrand: formatBrand(item.productBrand),
      }))
      // updating the wishlist to give user instantly a feedback, this is an optimistic update
      wishlistMemo.value.push(...itemsWithNotifyMe)
      const _items = mapStorageItems(items, configs.value.country)
      const updatedWishlist = [...rawWishlist.value, ..._items]
      // making http post request to api to save them persistently, so we can fetch them later after a browser refresh
      await wishlistStorage.createOrUpdate(updatedWishlist)
    } catch (e) {
      console.error(e)
      // if post request fails, restore the wishlist to its previous state, because it means that items have not been saved in db
      wishlistMemo.value = [...wishlistBeforeUpdate]
    } finally {
      await refresh({ dedupe: 'cancel' })
    }
  }

  const addItem = async (item: WishlistItem) => {
    deleteNotification(item.productCode)
    await addItems([item])
    addNotification(item, WishlistSnackbarAction.addToWishlist)
  }

  const restoreItem = async (item: WishlistItem | null) => {
    if (!item) return
    deleteNotification(item.productCode)
    await addItems([item])
  }

  const removeItem = async (
    productCode: string,
    disableNotification = false
  ) => {
    deleteNotification(productCode)
    const wishlistBeforeUpdate = [...wishlistMemo.value]
    const { removedItem, updatedItems } = diffItems(
      wishlistMemo.value,
      productCode
    )
    try {
      // optimistic update
      wishlistMemo.value = [...updatedItems]
      const updatedWishlist = rawWishlist.value.filter(
        (item: WishlistStorageItem) => item.productCode !== productCode
      )
      await wishlistStorage.createOrUpdate(updatedWishlist)
    } catch (e) {
      console.error(e)
      wishlistMemo.value = [...wishlistBeforeUpdate]
    } finally {
      await refresh({ dedupe: 'cancel' })
    }

    if (disableNotification) return removedItem
    addNotification(removedItem, WishlistSnackbarAction.removeFromWishlist)
    return removedItem
  }

  const deferredRemoveItem = () => {
    let updatedWishlist: WishlistStorageItem[] = [...rawWishlist.value]
    const removedItemsFromStorage: {
      pos: number
      value: WishlistStorageItem
    }[] = []
    return {
      removeItemFromStorage: async (item: WishlistItem) => {
        const storageItemIndex = rawWishlist.value.findIndex(
          ({ productCode }) => item.productCode === productCode
        )
        removedItemsFromStorage.push({
          pos: storageItemIndex,
          value: rawWishlist.value[storageItemIndex],
        })
        updatedWishlist = updatedWishlist.filter(
          ({ productCode }) => item.productCode !== productCode
        )
        await wishlistStorage.createOrUpdate(updatedWishlist).catch(e => {
          console.error(e)
        })
      },
      completeRemoveItem: async (item: WishlistItem) => {
        wishlistMemo.value = wishlistMemo.value.filter(
          ({ productCode }) => item.productCode !== productCode
        )
        await refresh({ dedupe: 'cancel' })
      },
      undoRemoveItem: async (item: WishlistItem) => {
        const itemToRestore = removedItemsFromStorage.find(
          ({ value }) => value.productCode === item.productCode
        )
        if (!itemToRestore) return
        const { pos, value } = itemToRestore
        updatedWishlist.splice(pos, 0, value)
        await wishlistStorage.createOrUpdate(updatedWishlist)
      },
    }
  }

  const addOrRemoveItem = useDebounceFn(
    async ({ item, view, forceRefresh = true }: AddOrRemoveItemParams) => {
      const alreadyExists = rawWishlist.value.find(
        ({ productCode }) => item.productCode === productCode
      )
      if (alreadyExists) {
        const removedItem = await removeItem(item.productCode)
        if (removedItem) dispatchRemoveFromWishlistEvent([removedItem])
        return
      }
      const wishlistItems = forceRefresh
        ? await fetchProductsData(
            mapStorageItems([item], configs.value.country)
          )
        : [item]
      dispatchAddToWishlistEvent(wishlistItems, view)
      await addItem(wishlistItems[0])
    },
    250
  )

  const restoreWishlist = async () => {
    try {
      deleteNotification('all')
      wishlistMemo.value = [...deletedWishlistItems.value]
      const restoredItems = mapStorageItems(
        deletedWishlistItems.value,
        configs.value.country
      )
      await wishlistStorage.createOrUpdate(restoredItems)
    } catch (e) {
      console.error(e)
    } finally {
      await refresh({ dedupe: 'cancel' })
    }
    deletedWishlistItems.value = []
  }

  const deleteWishlist = async () => {
    if (!rawWishlist.value.length) return
    try {
      isDeletingAll.value = true
      deletedWishlistItems.value = [...wishlistMemo.value]
      // optimistic update
      wishlistMemo.value = []
      dispatchRemoveFromWishlistEvent(deletedWishlistItems.value)
      await wishlistStorage.delete()
      addNotification(null, WishlistSnackbarAction.removeAll)
    } catch (e) {
      console.error(e)
      restoreWishlist()
    } finally {
      await refresh({ dedupe: 'cancel' })
      isDeletingAll.value = false
    }
  }

  const refreshWishlistOnTabChange = () => {
    const _refresh = async () => {
      if (document.hidden) return
      forceReload.value = true
      await refresh().finally(() => {
        forceReload.value = false
      })
    }
    return {
      addWishlistTabChangeListener: () => {
        document.addEventListener('visibilitychange', _refresh)
      },
      removeWishlistTabChangeListener: () => {
        document.removeEventListener('visibilitychange', _refresh)
      },
    }
  }

  watch(wishlistItemsByBrand, () => {
    wishlistEntity.value = {
      armani_exchange_wishlist:
        wishlistItemsByBrand.value.get('armani-exchange')?.length ?? 0,
      ea7_wishlist: wishlistItemsByBrand.value.get('ea7')?.length ?? 0,
      emporio_armani_wishlist:
        wishlistItemsByBrand.value.get('emporio-armani')?.length ?? 0,
      giorgio_armani_wishlist:
        wishlistItemsByBrand.value.get('giorgio-armani')?.length ?? 0,
      wishlist_products: wishlistItems.value.length,
      wishlistTotalValue: wishlistItems.value.reduce(
        (acc, { price }) => (acc += price),
        0
      ),
    }
  })

  return {
    wishlist: rawWishlist,
    wishlistItems,
    wishlistItemsByBrand,
    wishlistProductCodes,
    nProducts,
    isWishlistEmpty,
    isWishlistLoading,
    isDeletingAll,
    mergeWishlists,
    updateNotifyMe,
    addItem,
    restoreItem,
    removeItem,
    deferredRemoveItem,
    addOrRemoveItem,
    restoreWishlist,
    deleteWishlist,
    refreshWishlistOnTabChange,
  }
}
