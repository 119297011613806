import { countries } from '@design-system/configs/countries'

declare global {
  interface Window {
    RISKX: {
      go: (path: string) => void
    }
  }
}

export const useRiskified = async (id: string) => {
  const configs = useConfigs()
  const config = useRuntimeConfig()

  // default Europe shop
  const shopUrl = 'armani_eu.com'
  const _RFtoken = useState('rftoken', () => id)
  const RFtoken = unref(_RFtoken)
  // Cookie duration of 13 days in seconds
  const cookieLifetime = 1123200
  const cookiesUserSession = useCookie<{
    name: string | undefined
  }>('riskified-cookie', { maxAge: cookieLifetime })

  const country = countries.find(
    country => country.code === configs.value.country
  )

  if (
    !cookiesUserSession.value ||
    [
      '86745ac6283adbccf9859c684f7998ed',
      '0b7570da12132733e0da99c5b97aa127',
      'c83044295ae7ec4470df088da24425b8',
      '8dfd39bc85a76357c007455fb01d0dc7',
      'f364a1dcbc0b432df511e791dd504270',
      'b745b26f421aaf2b0461dc8566f15103',
      '9b487819b5538b9c0e70f38171682ced',
      'c6deeafb883a8ddf570d897c38562ad5',
      '6e870ee72a29a938469468a8eff12aaa',
      'f364a1dcbc0b432df511e791dd504270',
      'b745b26f421aaf2b0461dc8566f15103',
    ].includes(cookiesUserSession.value?.name ?? '')
  ) {
    cookiesUserSession.value = {
      name: RFtoken,
    }
  }

  const cookie = cookiesUserSession.value.name
  const store_domain =
    country && country.RFshopUrl ? country.RFshopUrl : shopUrl
  const session_id = cookie
  const urlRisk: string = `${'https:' === document.location.protocol ? 'https://' : 'http://'}beacon.riskified.com?shop=${store_domain}&sid=${session_id}`

  return useScript({
    src: urlRisk,
    defer: true,
  })
}
