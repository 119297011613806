import { convertStringToSlug } from '@design-system/utils/stringUtils'
import { withTrailingSlash } from 'ufo'

export function getPdpPath(
  algoliaProduct: {
    NameURL?:
      | {
          lang?: string | undefined
          name?: string | null | undefined
        }[]
      | undefined
    objectID: string
    Brand: string
  },
  lang: string,
  addTrailingSlash = true
) {
  const nameUrls =
    algoliaProduct.NameURL?.filter(nu => !!nu.lang && !!nu.name) ?? []
  const localizedName = nameUrls.find(
    name => name.lang === (lang || 'en')
  )?.name
  const urlWithMFC = [
    `/${convertStringToSlug(algoliaProduct.Brand)}`,
    `${convertStringToSlug(localizedName || '')}-cod-${algoliaProduct.objectID.replaceAll('_', '-')}`,
  ].join('/')

  return addTrailingSlash ? withTrailingSlash(urlWithMFC) : urlWithMFC
}
