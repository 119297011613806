import snakeCase from 'lodash/snakeCase'

export const mapRouteToGAPageType = (
  pathArray: Array<string>
): { pageType: string; subPageType: string } => {
  if (pathArray.some(el => el === 'my-account')) {
    // Get the last route
    const subPage = pathArray[pathArray.length - 1]
    const subPageFormatted = snakeCase(subPage)

    return { pageType: 'my_account', subPageType: subPageFormatted }
  }
  if (pathArray.some(el => el === 'checkout')) {
    return { pageType: 'checkout', subPageType: 'checkout' }
  }
  if (pathArray.some(el => el === 'legal')) {
    const subPage = pathArray[pathArray.length - 1]
    const subPageFormatted = snakeCase(subPage)
    return { pageType: 'legal', subPageType: subPageFormatted }
  }
  return { pageType: 'genericPage', subPageType: 'genericPage' }
}
