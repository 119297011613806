import type { TypeNavigationBarStatus } from '@design-system/composables/useMenu'

export default defineNuxtRouteMiddleware(async to => {
  const {
    setHeaderTransparencyStatus,
    setHeaderTransparencyColor,
    setHeaderMegalogo,
  } = useDefaultHeaderConfig()

  const { getBrandTheme } = await useBrandsSettings()

  const app = useNuxtApp()

  //Get current brand and current theme
  const slug = to.params && Array.isArray(to.params.slug) ? to.params.slug : []
  const currentBrand = slug.length > 0 ? slug[0] : ''
  const currentTheme = getBrandTheme(currentBrand)

  //Set deferredCurrentWebsite only if it's the first load
  const deferredCurrentWebsite = useState('deferred-current-website')
  if (!deferredCurrentWebsite.value) deferredCurrentWebsite.value = currentBrand

  //Set Menu state
  const navigationBarStatus = useState<TypeNavigationBarStatus>(
    'header-navigation-bar',
    () => ({
      anatomy: 'hp',
    })
  )

  //If it's a PDP, just set the transparency if the theme is 'giorgio-armani'
  if (app.payload['is_product']) {
    const hasTransparentHeader = currentTheme === 'giorgio-armani'
    if (hasTransparentHeader) {
      setHeaderTransparencyStatus(true)
    } else {
      setHeaderTransparencyStatus(false)
    }
    setHeaderMegalogo(false)
    setHeaderTransparencyColor()

    navigationBarStatus.value.anatomy = 'pdp'

    //reset the add to card button (HeaderActionAddToCart)
    const disable = useState('disabledMobileAddToCart')
    const outOfStock = useState('selectedExtOutOfStockMobile')
    const onlyStoreMobile = useState('onlyStoreMobile')
    disable.value = outOfStock.value = onlyStoreMobile.value = undefined

    return
  }

  navigationBarStatus.value.anatomy =
    app.payload?.amplience_page_type ===
    'https://armani.com/crossbrand-homepage-newest'
      ? 'hp'
      : 'default'

  //If it's not a PDP, read the header config
  if (app.payload?.header_config) {
    const config = app.payload?.header_config as {
      transparency: boolean
      color_transparency: 'black' | 'white'
      megalogo: boolean
    }

    setHeaderTransparencyStatus(config.transparency ?? false)
    setHeaderTransparencyColor(config.color_transparency)
    setHeaderMegalogo(config.megalogo ?? false)
  } else {
    setHeaderTransparencyStatus(false)
    setHeaderMegalogo(false)
  }

  if (app.payload['hp_has_editorial_values_top']) {
    useState('hp_has_editorial_values_top').value = true
  }
})
