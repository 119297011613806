import z from 'zod'

export const accountLoginSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('001'),
  eventCategory: z.literal('login'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('-'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const accountRegistrationSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('002'),
  eventCategory: z.literal('registration'),
  eventAction: z.literal('view_element'),
  eventLabel: z.literal('popup'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const accountLogoutSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('003'),
  eventCategory: z.literal('logout'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('-'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const accountOAuthSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('021'),
  eventCategory: z.literal('oauth'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const accountMenuSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('022'),
  eventCategory: z.literal('account_menu'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const accountPopupSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('023'),
  eventCategory: z.literal('popup_login'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})
