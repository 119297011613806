export const waitUntil = async <T>(
  isReady: () => boolean,
  fn: () => T,
  delay: number,
  max: number,
  currentTime = 0
): Promise<T | void> => {
  if (currentTime > max) return
  if (isReady()) return fn()
  await sleep(delay)
  return waitUntil(isReady, fn, delay, max, currentTime + delay)
}

// export const waitUntil = async <T>(
//   isReady: () => boolean,
//   fn: () => T,
//   delay: number,
//   max: number
// ): Promise<T | void> =>
//   new Promise(resolve => {
//     const interval = setInterval(() => {
//       if (!isReady()) return
//       clearInterval(interval)
//       const res = fn()
//       resolve(res)
//     }, delay)

//     setTimeout(() => {
//       clearInterval(interval)
//       resolve()
//     }, max)
//   })
