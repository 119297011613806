import {
  getConfigByCountry,
  getCountryFromUrl,
  getLanguageFromUrl,
  getCountryAndLanguage,
} from '@integration-layer/integrationConfig'

export default defineNuxtPlugin(({ _route }) => {
  if (useRuntimeConfig().public.isFederated) return
  const config = getConfigByCountry(_route)
  useAppConfig().config = config
  useAppConfig().currentLanguage = getLanguageFromUrl(_route.fullPath)
  useAppConfig().currentCountry = getCountryFromUrl(_route.fullPath)
  useAppConfig().currentLanguageCountry = getCountryAndLanguage(_route.fullPath)
})
