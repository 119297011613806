import type { BrandSettings } from '@integration-layer/ampliance/schemas/brand-settings-schema.localized'
import brandSettings from '@integration-layer/data/armani/brands-settings/settings.json'

type BrandsSettings = { [brand: string]: BrandSettings }

// Adapt the CRM response using the brands name as keys
const adaptBrandsSettings = (settings: BrandSettings[]) => {
  return settings.reduce((acc: BrandsSettings, item: BrandSettings) => {
    acc[convertStringToSlug(item.name)] = item
    return acc
  }, {} as BrandsSettings)
}

export const useBrandsSettings = () => {
  const brandSettingsResponse = ref(brandSettings.map(b => b.content))

  // Brands Settings array sorted by priority
  const brandSettingsSortedList = computed(
    () => brandSettingsResponse.value ?? []
  )

  const internalBrandSortedList = computed(() =>
    brandSettingsSortedList.value.filter(brand => !brand.isExternal)
  )

  // Brands Settings Object
  const brandsSettings = computed(
    // @ts-ignore
    () => adaptBrandsSettings(brandSettingsSortedList.value) ?? {}
  )

  // Brands Name Array
  const brandsName = computed(() =>
    brandSettingsSortedList.value.map(brand => brand.name)
  )

  const getBrandSettings = (brand: string) => {
    return brandsSettings.value?.[brand]
  }

  const getBrandName = (brand: string) => {
    return getBrandSettings(brand)?.name
  }

  const getAlgoliaBrandName = (brand: string) => {
    return getBrandSettings(brand)?.nameAlgolia
  }

  const getBrandAcronym = (brand: string) => {
    return getBrandSettings(brand)?.acronym
  }

  const getBrandLogo = (brand: string) => {
    return getBrandSettings(brand)?.logo
  }

  const getBrandTheme = (brand: string) => {
    return getBrandSettings(brand)?.theme
  }

  const getMonogramLogo = (brand: string) => {
    return getBrandSettings(brand)?.monogram
  }

  const shopEnabled = (brand: string) => {
    return getBrandSettings(brand)?.shop
  }
  const getShotRotation = (brand: string) => {
    return getBrandSettings(brand)?.shotRotation
  }

  const getNewsletterImage = (brand: string) => {
    return getBrandSettings(brand)?.newsletterImage
  }

  return {
    brandsSettings,
    brandSettingsSortedList,
    internalBrandSortedList,
    brandsName,
    getBrandSettings,
    getBrandLogo,
    getMonogramLogo,
    getBrandTheme,
    shopEnabled,
    getBrandName,
    getAlgoliaBrandName,
    getBrandAcronym,
    getShotRotation,
    getNewsletterImage,
  }
}
