import type { Country } from '@design-system/components/Footer/FooterAsideLanguage.props'
import originalCountries from '../data/countries.json'

type CountryMap = {
  [x: string]: string
}

export const allCountries: Country[] = originalCountries as Country[]
export const countries: Country[] = allCountries.filter(
  country =>
    (country.cl?.marketCode && !country.closed) || country.code === 'wx'
)

export const closedCountries: Country[] = allCountries.filter(
  country => country.closed
)

export const openCountries: Country[] = allCountries.filter(
  country => !country.closed
)

export const getAllActiveLanguageCountryCombinations = (): string[] => {
  const combinations: string[] = []
  countries.forEach(country => {
    country.languages.forEach(language => {
      combinations.push(`${language.langcode}-${country.code}`)
    })
  })

  return combinations
}

export const getAllDefaultPathForEachCountry = (): CountryMap => {
  const localeMap: CountryMap = {}
  const activeCountries = getAllActiveLanguageCountryCombinations()
  activeCountries.forEach(locale => {
    const [lang, country] = locale.split('-')
    if (!localeMap[country]) {
      if (['de', 'it', 'es', 'fr'].includes(lang) && lang === country) {
        localeMap[country] = locale
      } else {
        localeMap[country] = locale
      }
    }
  })
  return localeMap
}

export const getAvailableHrefCountryCombinations = (): string[] => {
  const combinations: string[] = []
  countries
    .filter(country => country.hrefCountry)
    .forEach(country => {
      country.languages.forEach(language => {
        combinations.push(`${language.langcode}-${country.code.toUpperCase()}`)
      })
    })

  return combinations
}
