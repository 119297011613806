import type { Brand, Website } from '@integration-layer/index'

const checkWebsite = (website: string) => {
  return brands.includes(website as Website)
}

const setWebsite = (currentWebsite: string | string[]) => {
  if (!checkWebsite(currentWebsite as string)) {
    useAppConfig().website = 'global'
    return
  }
  // else we set website
  useAppConfig().website = currentWebsite as Brand
}

export default defineNuxtRouteMiddleware(async to => {
  const currentWebsite = to.params?.website ?? to.path.split('/')[2]
  setWebsite(currentWebsite)
})
