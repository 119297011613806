import { resolveURL, withoutLeadingSlash } from 'ufo'
import { useRequestURL, useError } from '#imports'

export default defineNuxtRouteMiddleware(async to => {
  if (import.meta.prerender || import.meta.client) return

  const isIndex = to.name?.toString() === 'localized-index'
  const error = useError()
  const url = useRequestURL()

  if (!url.searchParams.has('pcode') || !isIndex || error.value) return

  const appConfig = useAppConfig()
  const language = appConfig.currentLanguage
  const { currentAlgoliaIndex } = appConfig

  const pcode = url.searchParams.get('pcode')?.replaceAll('-', '_')
  if (!pcode) return

  const productUrl = await $fetch(
    `/api/getProductUrl/${currentAlgoliaIndex}/${pcode}?lang=${language}`
  )
  const localizedProductUrl = resolveURL(
    appConfig.currentLanguageCountry,
    productUrl
  )

  const originalUrl = withoutLeadingSlash(url.pathname)
  if (localizedProductUrl === originalUrl) return
  // external: true is needed for a full page reload to avoid infinite redirects
  return navigateTo(localizedProductUrl, { external: true })
})
