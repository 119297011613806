import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export default defineNuxtRouteMiddleware((to, from) => {
  const app = useNuxtApp()
  const { loggedIn: isLogged } = useUserSession()

  // skip middleware if the user is logged in
  if (isLogged.value) return

  const newsletterShownCookie = useCookie<boolean | undefined>(
    'newsletterShown'
  )

  // check if banner has already appeared
  if (newsletterShownCookie.value) return

  // if everything is fine, start counting pages
  const pageCount = useSessionStorage('pageCount', 1)
  if (to.path !== from.path) pageCount.value++

  // before the third page, do nothing
  if (pageCount.value < 3) return

  const isPdp = !!app.payload?.['is_product']
  const excludedPages: string[] = [
    'localized-cart',
    // CHECKOUT ROUTES
    'localized-checkout',
    'localized-checkout-adyen',
    'localized-checkout-paypal',
    // HELP AREA ROUTES
    'localized-help',
    'localized-help-slug',
    'localized-help-ask-question',
    'localized-contact-us',
    'localized-help-contact-us-contact-form',
    'localized-help-faq',
    // LEGAL AREA ROUTES
    'localized-legal',
    'localized-legal-slug',
  ]

  const isAnExcludedPage: boolean =
    !!excludedPages.includes(to.name as string) || !!isPdp

  const { isAnyDialogOpen, openDialog: openNewsletterAside } = useDialog(
    SIDEBAR_IDS.newsletterSidebar
  )
  const { menuStatus: asideMenuStatus } = useMenu()

  // don't show sidebar if page is excluded or any other sidebar is open
  const skipSidebarShown =
    !!isAnExcludedPage ||
    isAnyDialogOpen.value ||
    asideMenuStatus.value.isActive

  if (!skipSidebarShown) {
    openNewsletterAside()
    //maxAge: 15 days
    useCookie<boolean | undefined>('newsletterShown', {
      maxAge: 15 * 24 * 60 * 60,
    }).value = true
  }
})
