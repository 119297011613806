import { useParamCart } from './useParamCart'
import { useParamGlobal } from './useParamGlobal'
import {
  dataLayerSetupSchema,
  type GASetupDatalayerParamItems,
  type GASetupDatalayerParamPage,
  type GASetupDatalayerParamSales,
  type GASetupDatalayerParamStore,
  type GASetupDatalayerParamUser,
  type GASetupDatalayerParamWishlist,
} from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import { z } from 'zod'
import { useResetParams } from './useResetParams'
type DatalayerSetup = z.infer<typeof dataLayerSetupSchema>

//Composable called on app.vue and error.vue on page change
export const useDatalayerSetup = async () => {
  const {
    setParamItems,
    setParamUser,
    setParamWishlist,
    setParamPages,
    defaultValues,
  } = useResetParams()
  const { isLogged } = useLogin()

  // paramSales
  // Merge the 2 different useGAEntity into salesDeliveryTypeEntity
  const salesDeliveryTypeEntity = useGAEntity<
    GASetupDatalayerParamSales['delivery_type'] | undefined
  >('GA_DL_SETUP_SALES_DELIVERY_TYPE')
  const salesPaymentTypeEntity = useGAEntity<
    Omit<GASetupDatalayerParamSales, 'delivery_type'> | undefined
  >('GA_DL_SETUP_SALES_PAYMENT_TYPE')
  const paramSales: GASetupDatalayerParamSales = {
    delivery_type: salesDeliveryTypeEntity.value ?? '',
    payment_type: salesPaymentTypeEntity.value?.payment_type ?? '',
    checkout_type: isLogged.value ? 1 : 0,
  }

  // paramUser
  // Read GA_DL_SETUP_USER useState
  const userEntity = useGAEntity<GASetupDatalayerParamUser>('GA_DL_SETUP_USER')
  const paramUser = setParamUser(userEntity.value)

  // paramItems
  // Read GA_DL_SETUP_ITEMS useState
  const itemsEntity =
    useGAEntity<GASetupDatalayerParamItems>('GA_DL_SETUP_ITEMS')
  const paramItems = setParamItems(itemsEntity.value)

  // paramWishlist
  // Read GA_DL_SETUP_WISHLIST useState
  const wishlistEntity = useGAEntity<GASetupDatalayerParamWishlist>(
    'GA_DL_SETUP_WISHLIST'
  )
  const paramWishlist = setParamWishlist(wishlistEntity.value)

  // paramPage
  // Read GA_DL_SETUP_PAGE inside useParamPage (the default values are already set in the composable useParamPage)
  const pageEntity = useGAEntity<GASetupDatalayerParamPage | undefined>(
    'GA_DL_SETUP_PAGE'
  )
  const paramPage = setParamPages(pageEntity.value)

  // paramStore
  // TODO: need to wait for store locator page
  const paramStore: GASetupDatalayerParamStore = { city_store: '', store: '' }

  const eventPayload: DatalayerSetup = {
    event: 'Armani_DL_Setup',
    paramCart: useParamCart(),
    paramGlobal: useParamGlobal(),
    paramSales,
    paramUser,
    paramItems,
    paramWishlist,
    paramPage,
    paramStore,
  }

  useGASendEvent(dataLayerSetupSchema, eventPayload)
  // Reset the items entity
  itemsEntity.value = defaultValues.paramItems
  const nuxt = useNuxtApp()

  // Call hook to let others events "know" that datalayer has done and they can fire
  //@ts-ignore
  nuxt.callHook('app:after-page-view')
}
