import type { Order, Sku } from '@commercelayer/sdk'

import z from 'zod'
import snakeCase from 'lodash/snakeCase'
import {
  cartItemSchema,
  ecommerceCartSchema,
} from '@integration-layer/schemas/GAEvents/cart'

type EcommerceCartSchema = z.infer<typeof ecommerceCartSchema>
type CartItem = z.infer<typeof cartItemSchema>
type AlgoliaObject = z.infer<typeof algoliaProductsSchema>

type MapDataParams = {
  cart: Order
  algoliaObjects: Array<AlgoliaObject | null>
  actionFromChat?: boolean
  qty?: number
}

export const useMapGAEcommerceData = () => {
  const { currentAlgoliaIndex: algoliaIndex } = useAppConfig()
  const getAlgoliaObjects = async (codes: string[]) => {
    if (!codes.length) return
    const response = await $fetch(`/api/getProducts/${algoliaIndex}`, {
      query: {
        mfcList: codes.map(code => getSkuWithoutSize(code)).join(','),
      },
    })
    const products = response.filter(obj => !!obj)
    if (!products.length) throw new Error('products not found')
    return products
  }

  const mapData = async ({
    cart,
    algoliaObjects,
    actionFromChat,
    qty,
  }: MapDataParams) => {
    const lineItems =
      cart?.line_items?.filter(({ item_type }) => item_type === 'skus') ?? []
    if (!algoliaObjects?.length && lineItems.length) {
      try {
        const SKUs = lineItems.reduce<string[]>((acc, { sku_code }) => {
          if (sku_code) acc.push(sku_code)
          return acc
        }, [])
        algoliaObjects = (await getAlgoliaObjects(SKUs)) ?? []
      } catch {
        return
      }
    }

    if (!lineItems.length) return

    const clientService = actionFromChat ? 1 : 0
    const items = lineItems.map((lineItem, index) => {
      const algoliaObject = algoliaObjects?.find(
        obj => obj?.objectID === getSkuWithoutSize(lineItem.sku_code)
      )

      const item = lineItem?.item as Sku
      const brand = algoliaObject?.Brand ?? ''
      const discount = lineItem?.discount_float ?? 0

      const isAvailable =
        item?.inventory?.available && item?.inventory?.quantity > 0

      const price =
        lineItem?.unit_amount_float! +
        lineItem?.discount_float! / lineItem?.quantity

      const { category1, category2, category4 } = extractCategories(
        algoliaObject?.hierarchicalCategories
      )

      return {
        item_id: algoliaObject?.objectID ?? '',
        item_name: snakeCase(lineItem.name ?? ''),
        affiliation: 'armani.com',
        coupon: cart.coupon_code ?? '',
        discount: Math.abs(discount) / lineItem?.quantity,
        index: index + 1,
        item_brand:
          brand.toLowerCase() === 'ea7'
            ? 'EA7'
            : snakeCase(brand ?? 'armani group'),
        item_category: snakeCase(category1),
        item_category2: snakeCase(category2),
        item_category4: snakeCase(category4),
        item_list_id: '',
        item_list_name: snakeCase(`${brand}-${category2}`),
        item_variant: lineItem.metadata?.color ?? '',
        price,
        quantity: qty ?? lineItem.quantity,
        item_availability: isAvailable ? 1 : 0,
        item_size: lineItem.metadata?.size ?? '',
        item_reservable: 0,
        item_discount: discount ? 1 : 0,
        item_mfc_id: algoliaObject?.MFC ?? '',
        item_img: lineItem.image_url ?? '',
        item_client_service: clientService,
      } satisfies CartItem
    })

    const totalPrice = items
      .map(item => item.price * item.quantity)
      .reduce((acc, curr) => acc + curr, 0)

    return {
      client_service: clientService,
      currency: lineItems?.[0]?.currency_code ?? 'EUR',
      value: totalPrice,
      items,
    } satisfies EcommerceCartSchema
  }

  return { mapData }
}
