import type { Brand } from '@integration-layer/index'

const isBrand = (w: string) => (brands as string[]).includes(w)

export const sortItemsByBrand = <Item>(
  array: Item[],
  getWebsite: (item: Item) => string
) => {
  const brandItems = array.reduce(
    (acc, item) => {
      const website = getWebsite(item) as Brand
      if (!isBrand(website!)) return acc
      acc[website] = acc[website] || []
      acc[website].push(item)
      return acc
    },
    {} as Record<Brand, Item[]>
  )
  const sortedItemsByBrand = new Map<Brand, Item[]>()
  brands.forEach((brand: Brand) => {
    if (brandItems[brand]) {
      sortedItemsByBrand.set(brand, brandItems[brand])
    }
  })
  return sortedItemsByBrand
}
