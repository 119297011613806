import { useBreakpoints } from '@vueuse/core'
import screens from '@design-system/configs/tailwind/screens'

export const useProjectBreakpoint = () => {
  const breakpoint = useBreakpoints(screens)

  return {
    breakpoint,
  }
}
