<script setup lang="ts">
import { useDatalayerSetup } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useDatalayerSetup'
import { useParamPage } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useParamPage'

const props = defineProps<{
  error: {
    url: string
    statusCode: number
    message: string
  }
}>()

const { mergeTranslations, getLocale } = useI18n()
const locale = getLocale()
const { ts } = useI18n()

const { currentWebsite, deferredCurrentWebsite } = useWebsite()

deferredCurrentWebsite.value = currentWebsite.value

const translations = await $fetch(
  `/_locales/general/${locale === 'en' ? 'en-us' : locale}/data.json`
)
mergeTranslations(translations!)

if (props.error.statusCode === 500) {
  console.log('Error: 500', props.error.message)
}
useParamPage('errorPage', {
  pageStatusCode: props.error.statusCode,
})

// const { data } = await useErrorPage(props.error.statusCode)

const key = `errorPage.${props.error.statusCode as unknown as string}`

const data = {
  _meta: {
    name: '404',
    schema: 'https://armani.com/error-page',
    deliveryKey: 'error-page-404',
    deliveryId: '19eaa4c9-5236-4e04-a0b0-89d35212f8fe',
  },
  cta1: {
    externalLink: 'https://main.prod.armani.com/',
  },
  cta2: {
    externalLink: 'https://main.prod.armani.com/',
  },
  title: ts(`${key}.title`),
  subtitle: ts(`${key}.subTitle`),
  image: {
    secure_url:
      'https://assets.armani.com/image/upload/v1729866290/Armani-Background-Page-404.jpg',
    public_id: 'Armani-Background-Page-404',
  },
  gradient: '',
  textColor: 'rgb(0, 0, 0)',
  cta1Text: ts(`${key}.backPrevPageText`),
  cta2Text: ts(`${key}.backHomeText`),
}

const localePath = useLocalePath()
useTimeoutFn(() => {
  useDatalayerSetup()
}, 500)

const redirect = (type: string) => {
  if (type === 'prev') {
    window.history.back()
  } else {
    window.location.href = localePath('/')
  }
}
</script>

<template>
  <NuxtLayout>
    <div
      v-if="error"
      class="relative flex h-screen w-full flex-col items-center justify-center text-center"
      :style="{
        color: `${data?.textColor}`,
      }"
    >
      <div
        :style="[
          data?.gradient && {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 1) -10%, ${data?.gradient} 30%, rgba(255, 255, 255, 1) 250%)`,
          },
        ]"
        class="opacity-layer"
      ></div>
      <div class="img-bg">
        <NuxtPicture
          :src="data?.image?.public_id ?? ''"
          :alt="data?.image?.public_id ?? ''"
          :img-attrs="{ class: 'h-full w-full object-cover aspect-1/1' }"
          class="relative -z-10"
          sizes="sm:100vw"
          provider="cloudinary"
        />
      </div>
      <div>
        <div class="px-10">
          <h1 class="text-medium-1">{{ error.statusCode }}</h1>
          <p class="text-light-10">
            {{ data?.title }}
          </p>
          <p class="text-light-7 mt-2">
            {{ data?.subtitle }}
          </p>
        </div>

        <div class="mt-7 flex justify-center gap-4">
          <AtomsCta
            size="l"
            :icon-show-default="false"
            anatomy="secondary"
            class="bg-neutral-white text-neutral-black z-10"
            @click="redirect('prev')"
          >
            <template #label>
              {{ data?.cta1Text }}
            </template>
          </AtomsCta>
          <AtomsCta
            size="l"
            anatomy="secondary"
            :icon-show-default="false"
            class="bg-neutral-white text-neutral-black z-10"
            @click="redirect('home')"
          >
            <template #label>
              {{ data?.cta2Text }}
            </template>
          </AtomsCta>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
<style scoped lang="scss">
.opacity-layer {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 0;
}

.img-bg {
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
