export default defineNuxtRouteMiddleware(to => {
  const { loggedIn: isLogged } = useUserSession()
  const localePath = useLocalePath(to)

  if (
    !isLogged.value &&
    (to.name as string)?.includes('my-account') &&
    !(to.name as string)?.includes('help/my-account')
  ) {
    return navigateTo(localePath('/signup'))
  }
})
