import { countries } from '@design-system/configs/countries'

export const useCurrency = () => {
  const configs = useConfigs()

  const currentCurrency = computed<string>(() => {
    const selectedCountry = countries.find(
      c => c.code === configs.value.country
    )
    if (!selectedCountry) return 'EUR'
    return selectedCountry.cl.currency ?? 'EUR'
  })

  const getCurrencySymbolFromCountryCode = (countryCode: string) => {
    const selectedCountry = countries.find(c => c.code === countryCode)
    if (!selectedCountry) return null
    return {
      symbol: selectedCountry.cl.currencySymbol,
      code: selectedCountry.cl.currency,
    }
  }

  const getCurrencySymbol = (currencyCode: Uppercase<string>) => {
    const selectedCountry = countries.find(
      c => c.code === configs.value.country && c.cl.currency === currencyCode
    )
    const fallbackCountry = countries.find(c => c.cl.currency === currencyCode)

    return (
      selectedCountry?.cl?.currencySymbol ??
      fallbackCountry?.cl?.currencySymbol ??
      currencyCode
    )
  }

  const getCurrencyCodeFromCountryCode = (
    countryCode: string
  ): string | null => {
    const selectedCountry = countries.find(c => c.code === countryCode)
    if (!selectedCountry) return null
    return selectedCountry.cl.currency ?? null
  }

  return {
    currentCurrency,
    getCurrencySymbol,
    getCurrencySymbolFromCountryCode,
    getCurrencyCodeFromCountryCode,
  }
}
