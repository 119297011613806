import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export type TypeMenuStatus = {
  isActive: boolean
  currentSubMenu: {
    id: string
    label: string
  } | null
  activeLevels: string[]
}

export type TypeNavigationBarStatus = {
  anatomy: 'default' | 'hp' | 'pdp'
}

export const useMenu = () => {
  const { addToCart } = useAddToCart()

  const baseUrl = computed(() => useRuntimeConfig().public.baseUrl)

  const { breakpoint } = useProjectBreakpoint()
  const isMobile = breakpoint.isSmallerOrEqual('md')
  const { myAccount } = useRouteHelper()
  const { isLogged } = useLogin()
  const { countryLocale } = useCountryLocale()

  const { closeAllDialogs, openMenuDialog } = useDialog()
  const { isDialogOpen: isListViewOpen } = useDialog(
    SIDEBAR_IDS.popupLayerListView
  )
  const { isDialogOpen: isSearchOpen } = useDialog(
    SIDEBAR_IDS.globalSearchModal
  )
  const { isDialogOpen: isMyAccountOpen } = useDialog(SIDEBAR_IDS.loginSidebar)

  const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)

  const { dispatchGlobalNavigationEvent } = useGANavigationEvents()
  const { dispatchInternalSearchButton } = useGAInternalSearchEvents()

  //--Menu
  const menuStatus = useState<TypeMenuStatus>('menu-status', () => {
    return {
      isActive: false,
      currentSubMenu: null,
      activeLevels: [],
    }
  })

  const headerMonogramHeightCssVar = useCssVar(
    '--monogram-strip-height',
    null,
    {
      observe: true,
    }
  )
  const headerMonogramHeight = removePx(headerMonogramHeightCssVar.value)

  const toggleLevel = (level: string, panel: number) => {
    const isLvlActive = isLevelActive(level, panel)
    if (panel === 0) {
      menuStatus.value.activeLevels = []
    }
    menuStatus.value.activeLevels[panel] = isLvlActive ? '' : level
  }

  const isLevelActive = (level: string, panel: number): boolean => {
    return menuStatus.value.activeLevels[panel] === level
  }

  const closeLast = () => {
    if (
      menuStatus.value?.activeLevels?.length &&
      menuStatus.value.activeLevels[0]
    ) {
      menuStatus.value.activeLevels.pop()
    } else {
      cleanMenuStatus()
    }
  }

  const hasOtherItemActive = (level: string, panel: number): boolean => {
    if (isLevelActive(level, panel)) return false

    return !!menuStatus.value.activeLevels[panel]?.length
  }

  //--NavBar
  const navigationBarStatus = useState<TypeNavigationBarStatus>(
    'header-navigation-bar',
    () => ({
      anatomy: 'hp',
    })
  )

  //--Cart
  const cartStatus = useState('menu-cart', () => false)

  const cleanMenuStatus = () => {
    menuStatus.value.activeLevels = []
    menuStatus.value.isActive = false
  }

  const closeAll = () => {
    closeAllDialogs()
    menuStatus.value.isActive = false
  }

  const closeDialogs = () => {
    cleanMenuStatus()
    closeAll()
  }

  const toggleMenu = () => {
    const menuElement = document.getElementById('menu-content')
    menuElement?.scrollTo({ top: 0, behavior: 'auto' })

    closeAllDialogs()
    menuStatus.value.isActive = !menuStatus.value.isActive
    if (!menuStatus.value.isActive) cleanMenuStatus()
    else dispatchGlobalNavigationEvent('menu')
  }

  const toggleMenuInPdp = () => {
    toggleMenu()

    requestAnimationFrame(() => {
      if (
        headerMonogramHeight &&
        !menuStatus.value.isActive &&
        window.scrollY <= headerMonogramHeight
      ) {
        window.scrollTo({
          top: headerMonogramHeight,
          behavior: 'auto',
        })
      }
    })
  }

  const navigateToMyAccountPage = () => {
    navigateTo(`${baseUrl.value}/${countryLocale.value}/my-account`, {
      external: true,
    })
  }

  const toggleMyAccount = () => {
    if (isFederatedApp.value) {
      navigateToMyAccountPage()
      return
    }

    dispatchGlobalNavigationEvent('account')
    if (!isMyAccountOpen.value) {
      if (isMobile && isLogged.value) {
        navigateTo(myAccount)
      } else {
        closeAll()
        openMenuDialog(SIDEBAR_IDS.loginSidebar)
      }
    } else {
      closeAll()
    }
  }

  const toggleListView = () => {
    if (!isListViewOpen.value) {
      closeAll()
      openMenuDialog(SIDEBAR_IDS.popupLayerListView)
    } else {
      closeDialogs()
    }
  }

  const openSearchView = () => {
    closeAll()
    dispatchInternalSearchButton()
    nextTick(() => {
      openMenuDialog(SIDEBAR_IDS.globalSearchModal)
    })
  }

  const navigateToSupportPage = () => {
    navigateTo(`${baseUrl.value}/${countryLocale.value}/help/contact-us`, {
      external: true,
    })
  }

  const openSupportView = () => {
    if (isFederatedApp.value) {
      navigateToSupportPage()
      return
    }

    closeAll()
    openMenuDialog(SIDEBAR_IDS.cartContactUsSideSlide)
    dispatchGlobalNavigationEvent('contact_us')
  }

  return {
    isFederatedMenu: isFederatedApp,
    menuStatus,
    cartStatus,
    navigationBarStatus,
    isLogged,
    isListViewOpen,
    isSearchOpen,
    hasOtherItemActive,
    toggleLevel,
    isLevelActive,
    closeLast,
    toggleMenu,
    toggleMenuInPdp,
    toggleListView,
    addToCart,
    openSupportView,
    toggleMyAccount,
    openSearchView,
    cleanMenuStatus,
    closeDialogs,
    navigateToMyAccountPage,
    navigateToSupportPage,
  }
}
