import z from 'zod'

export const clickCTASchema = z.object({
  event: z.literal('GAevent'),
  eventAction: z.literal('click_cta'),
})

export const globalNavigationLabelsSchema = z.enum([
  'menu',
  'search',
  'account',
  'cart',
  'contact_us',
])

export const globalNavigationSchema = clickCTASchema.extend({
  eventID: z.literal('005'),
  eventCategory: z.literal('global_navigation'),
  eventLabel: globalNavigationLabelsSchema,
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const headerSchema = clickCTASchema.extend({
  eventID: z.literal('006'),
  eventCategory: z.literal('header'),
  eventLabel: z.literal('strips'),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const menuSchema = clickCTASchema.extend({
  eventID: z.literal('007'),
  eventCategory: z.literal('menu'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.string(),
})

export const storeLocatorSchema = clickCTASchema.extend({
  eventID: z.literal('010'),
  eventCategory: z.literal('store_locator'),
  eventLabel: z.literal('Find a Boutique'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const subMenuSchema = clickCTASchema.extend({
  eventID: z.literal('011'),
  eventCategory: z.literal('submenu'),
  eventLabel: z.string(),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const footerSchema = clickCTASchema.extend({
  eventID: z.literal('012'),
  eventCategory: z.literal('footer'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const fitAnalyticsSchema = clickCTASchema.extend({
  eventID: z.literal('014'),
  eventCategory: z.literal('fitanalytics'),
  eventLabel: z.literal('start'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const cookieBannerLabels = z.enum([
  'accept_all',
  'deny_all',
  'preferences',
  'preferences_saved',
])

export const cookieBannerSchema = clickCTASchema.extend({
  eventID: z.literal('016'),
  eventCategory: z.literal('banner_cookie'),
  eventLabel: cookieBannerLabels,
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

//TODO: refetch schema for amplience
// export const socials = brandSocialItemSchema.shape.social
export const socials = z.any()
export const socialSchema = clickCTASchema.extend({
  eventID: z.literal('020'),
  eventCategory: z.literal('social'),
  eventLabel: socials,
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const helpSchema = clickCTASchema.extend({
  eventID: z.literal('026'),
  eventCategory: z.literal('thank_you_page'),
  eventLabel: z.literal('contact_us'),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.number(),
})
