export default defineNuxtPlugin({
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    'app:created'() {
      useHead({
        script: [
          {
            type: 'text/javascript',
            async: false,
            defer: false,
            innerHTML: `(function (u, t, d) {
        var i = d.createElement(t)
        i.type = 'text/javascript'
        i.async = true
        i.src = '//' + u
        var s = d.getElementsByTagName(t)[0]
        s.parentNode.insertBefore(i, s)
      })(
        'cdn8.eu.inside.chat/gtm/IN-1001177-NEW/include.js',
        'script',
        document
      )`,
          },
        ],
      })
    },
  },
})
