import type { RouteLocationNormalized } from 'vue-router'
import { countries } from '../design-system/configs/countries'

export const configMap = countries.map(country => ({
  country: country.code,
  languages: country.languages,
  is_shoppable: country.isShoppable,
  cl_market_code: country.cl.marketCode,
}))

export type TConfigMap = typeof configMap

/**
 * Returns an array of language-country pairs based on the given `configMap`.
 *
 * @return {Array<string>} An array of language-country pairs in the format "language-country".
 */
export const getLangCountryMap = () => {
  return Array.from(configMap).flatMap(config =>
    config.languages.map(lang => `${lang.langcode}-${config.country}`)
  )
}

export const getLangCountryMapI18n = () => {
  return Array.from(configMap).flatMap(config =>
    config.languages.map(lang => ({
      code: `${lang.langcode}-${config.country}`,
      // files: [lang.langcode],
      iso: `${lang.langcode}-${config.country.toUpperCase()}`,
    }))
  )
}

/**
 * Generates a string of language-country pairs.
 *
 * @return {string} The string of language-country pairs.
 */
export const getLangCountryString = (): string => getLangCountryMap().join(',')

const configs: Record<string, (typeof configMap)[number]> = configMap.reduce(
  (configList, conf) => {
    configList[conf.country] = conf
    return configList
  },
  {} as Record<string, (typeof configMap)[number]>
)

export const getConfigByCountry = (to: RouteLocationNormalized) => {
  const defaultCountry = 'wx'
  if (!to) return configs[defaultCountry]
  if (!to.fullPath || !to.fullPath.includes('/')) return configs[defaultCountry]

  const country = getCountryFromUrl(to.fullPath)
  if (!country || !configs[country]) return configs[defaultCountry]

  return configs[country]
}

export const getCountryAndLanguage = (url: string) => {
  return url.split('/')[1]
}

export const getCountryFromUrl = (url: string): string => {
  const countryLang = getCountryAndLanguage(url)
  const country = countryLang.split('-')[1]
  return country || ''
}

export const getLanguageFromUrl = (url: string): string => {
  const countryLang = getCountryAndLanguage(url)
  const language = countryLang.split('-')[0]

  return language || ''
}
