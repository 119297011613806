import type { WishlistItem } from '@integration-layer/utils/wishlist'

export enum WishlistSnackbarAction {
  addToWishlist = 'addToWishlist',
  removeFromWishlist = 'removeFromWishlist',
  removeAll = 'removeAll',
}

type Notification = {
  item: WishlistItem | null
  action: WishlistSnackbarAction
  timeout: ReturnType<typeof setTimeout>
}

type ActionsCallback = {
  add?: () => void
  remove?: () => void
  removeAll?: () => void
}

type getSnackbarMessageParams = {
  action: WishlistSnackbarAction
  productId?: string
  actionsCb?: ActionsCallback
}

export const useWishlistFeedback = (notificationClosingTime = 5000) => {
  const { ts } = useI18n()
  const { loggedIn } = useUserSession()
  const { wishlist, guestWishlist, getCanonicalUrl } = useRouteHelper()
  const wishlistFeedbackNotifications = useState(
    'WISHLIST_FEEDBACK_NOTIFICATIONS',
    () => new Map<string, Notification>()
  )

  const wishlistRoute = computed(() =>
    loggedIn.value ? wishlist : guestWishlist
  )

  const addNotification = (
    item: WishlistItem | null,
    action: WishlistSnackbarAction
  ) => {
    const productCode = item?.productCode ?? 'all'
    wishlistFeedbackNotifications.value.set(productCode, {
      item,
      action,
      timeout: setTimeout(() => {
        if (wishlistFeedbackNotifications.value.has(productCode)) {
          wishlistFeedbackNotifications.value.delete(productCode)
        }
      }, notificationClosingTime),
    })
  }

  const deleteNotification = (productCode: string) => {
    if (wishlistFeedbackNotifications.value.has(productCode)) {
      clearTimeout(
        wishlistFeedbackNotifications.value.get(productCode)?.timeout
      )
      wishlistFeedbackNotifications.value.delete(productCode)
    }
  }

  const getSnackbarMessage = ({
    productId,
    action,
    actionsCb,
  }: getSnackbarMessageParams) => {
    const actions = {
      [WishlistSnackbarAction.addToWishlist]: {
        message: ts('feedback.addedToWishlist'),
        actionMessage: ts('feedback.goToWishlist'),
        action: () => {
          if (productId) deleteNotification(productId)
          navigateTo(getCanonicalUrl(wishlistRoute.value))
          actionsCb?.add?.()
        },
      },
      [WishlistSnackbarAction.removeFromWishlist]: {
        message: ts('feedback.removedFromWishlist'),
        actionMessage: ts('feedback.undo'),
        action: actionsCb?.remove,
      },
      [WishlistSnackbarAction.removeAll]: {
        message: ts('feedback.removeAll'),
        actionMessage: ts('feedback.undo'),
        action: actionsCb?.removeAll,
      },
    }
    return actions[action]
  }

  const clearWishlistNotifications = () =>
    onBeforeUnmount(() => {
      wishlistFeedbackNotifications.value.forEach(notification => {
        clearTimeout(notification.timeout)
      })
      wishlistFeedbackNotifications.value.clear()
    })

  return {
    wishlistFeedbackNotifications,
    notificationClosingTime,
    addNotification,
    deleteNotification,
    getSnackbarMessage,
    clearWishlistNotifications,
  }
}
