import { z } from 'zod'
const binaryChoice = z.literal(0).or(z.literal(1))

export const dataLayerSetupSchema = z.object({
  event: z.literal('Armani_DL_Setup'),
  paramGlobal: z.object({
    environment: z.enum(['development', 'staging', 'production']),
    device: z.enum(['m', 't', 'd']),
    current_shipping: z.string(),
    website_language: z.string(),
    selected_currency: z.string(),
    localDate: z.string(),
    local_hour_minute: z.string(),
    local_parts_of_day: z.enum(['night', 'morning', 'afternoon', 'evening']),
    local_office_hours: z.enum(['office', 'lunch', 'out_of_office']),
    timestamp: z.string(),
  }),
  paramUser: z.object({
    userLogged: binaryChoice,
    userID: z.string(),
    registrationDate: z.string(),
    userType: z.string(),
    userAge: z.string(),
    userGender: z.enum(['male', 'female', 'unknown']),
    cluster_age: z.string(),
    SHA256_hashed_userEmail: z.string(),
    registeredNewsletter: binaryChoice,
    userName: z.string(),
    userEmail: z.string(),
    userSurname: z.string(),
    userPhone: z.string(),
  }),
  paramPage: z.object({
    page_status_code: z.number(),
    page_type: z.string(),
    sub_page_type: z.string(),
    department: z.string(),
    brand: z.string(),
    gender: z.enum(['U', 'D', 'E']),
  }),
  paramItems: z.object({
    itemOutOfStock: binaryChoice,
    storeAvailability: binaryChoice,
    item_category_level_1: z.string(),
    item_category_level_2: z.string(),
    shop_category: z.enum(['item', 'searchresult', 'category']),
    product_listSize: z.number(),
    product_listtype: z.string(),
  }),
  paramCart: z.object({
    cartID: z.string(),
    cart_type: z.enum(['mono_brand', 'multi_brand']),
    emporio_armani_cart: z.number(),
    armani_exchange_cart: z.number(),
    ea7_cart: z.number(),
    giorgio_armani_cart: z.number(),
    client_service_cart: binaryChoice,
    cart_products: z.number(),
    cartTotalValue: z.string(),
  }),
  paramWishlist: z.object({
    armani_exchange_wishlist: z.number(),
    ea7_wishlist: z.number(),
    emporio_armani_wishlist: z.number(),
    giorgio_armani_wishlist: z.number(),
    wishlist_products: z.number(),
    wishlistTotalValue: z.number(),
  }),
  paramSales: z.object({
    checkout_type: binaryChoice,
    payment_type: z.string(),
    delivery_type: z.string(),
  }),
  paramStore: z.object({
    store: z.string(),
    city_store: z.string(),
  }),
})

export type GASetupDatalayerParamGlobal = z.infer<
  typeof dataLayerSetupSchema
>['paramGlobal']
export type GASetupDatalayerParamCart = z.infer<
  typeof dataLayerSetupSchema
>['paramCart']
export type GASetupDatalayerParamItems = z.infer<
  typeof dataLayerSetupSchema
>['paramItems']
export type GASetupDatalayerParamPage = z.infer<
  typeof dataLayerSetupSchema
>['paramPage']
export type GASetupDatalayerParamSales = z.infer<
  typeof dataLayerSetupSchema
>['paramSales']
export type GASetupDatalayerParamUser = z.infer<
  typeof dataLayerSetupSchema
>['paramUser']
export type GASetupDatalayerParamWishlist = z.infer<
  typeof dataLayerSetupSchema
>['paramWishlist']
export type GASetupDatalayerParamStore = z.infer<
  typeof dataLayerSetupSchema
>['paramStore']
export type GASetupDatalayer = z.infer<typeof dataLayerSetupSchema>
