import type { ProductSchema as AlgoliaProductType } from '@integration-layer/.nuxt/armani/product-schema'
import { useParamPageItem } from './useParamPageItem'
import { useResetParams } from './useResetParams'
import { type GASetupDatalayerParamPage } from '@integration-layer/schemas/GAEvents/dataLayerSetup'

export const useParamPage = (
  pageType: 'productPage' | 'ampliencePage' | 'errorPage' | 'genericPage',
  payload?: {
    item?: AlgoliaProductType
    pageType?: string
    pageStatusCode?: number
    brand?: string
    gender?: string
    categories?: Array<string>
  }
) => {
  const { defaultValues } = useResetParams()
  const { country } = useRouteHelper()
  const formattedBrand = useGAFormattedBrands()

  const gender = payload?.gender === 'man' ? 'M' : 'W'

  let paramPage: GASetupDatalayerParamPage = defaultValues.paramPage
  const isProduct = pageType === 'productPage'
  const isAmpliencePage = pageType === 'ampliencePage'
  const isErrorPage = pageType === 'errorPage'
  const isGenericPage = pageType === 'genericPage'

  const pageEntity = useGAEntity<GASetupDatalayerParamPage>('GA_DL_SETUP_PAGE')

  if (isProduct) {
    // absence of payload.item is an error, fallback to default values
    if (payload?.item) {
      paramPage = useParamPageItem(payload.item)
    }
  } else if (isAmpliencePage) {
    paramPage.brand = formattedBrand
    const pageType = mapAmplienceSchemaToGAPageType(payload?.pageType ?? '')
    paramPage.page_type = pageType
    if (pageType === 'subhome') {
      const lifestyleBrands = [
        'armani_casa',
        'armani_fiori',
        'armani_clubs',
        'armani_restaurants',
      ]
      if (lifestyleBrands.includes(formattedBrand)) {
        paramPage.page_type = 'lifestyle'
      }
      paramPage.sub_page_type = formattedBrand
    }
    if (pageType === 'category') {
      let subPageTypeCategory = `${country.toUpperCase()}_${mapShortWebsitesAlias(formattedBrand)}_${gender}`

      // Append every ctegory from the array of the payload
      payload?.categories?.forEach(
        category => (subPageTypeCategory = `${subPageTypeCategory}_${category}`)
      )
      paramPage.sub_page_type = subPageTypeCategory
    }
    // Sub function
  } else if (isGenericPage) {
    // Page is not Amplience-based or error, infer page type from route
    const path = useRoute().path as string
    const pathArray = removeLastEmptyParam(path.split('/')) as string[]
    const { pageType, subPageType } = mapRouteToGAPageType(pathArray)

    paramPage.page_type = pageType
    paramPage.sub_page_type = subPageType
  } else if (isErrorPage) {
    //Overwrite with status code and set 500 in case of unmapped error
    paramPage.page_status_code = payload?.pageStatusCode ?? 500
    paramPage.page_type = payload?.pageStatusCode?.toString() ?? '500'
  }
  pageEntity.value = paramPage
}
