import type { User } from '@integration-layer/server/utils/auth0Utils.ts'

export const useUserMeta = () => {
  const key = 'useUserMeta'

  const nuxtApp = useNuxtApp()
  const { refresh } = useFetch('/api/auth0/getUserMetadata', {
    key,
    immediate: false,
    server: false,
    dedupe: 'defer',
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })
  const userMetaState = useState<User | null>(key, () => null)

  const initUserMeta = async () => {
    if (userMetaState.value) return
    await refresh()
    userMetaState.value = nuxtApp.payload.data[key]
  }

  const refreshUserMeta = async () => {
    await refresh({ _initial: false, dedupe: 'cancel' })
    userMetaState.value = nuxtApp.payload.data[key]
  }

  const userMeta = computed(
    () => userMetaState.value?.user_metadata as TUserMeta
  )
  const userData = computed(() => userMetaState.value)

  return {
    userMeta,
    userData,
    initUserMeta,
    refreshUserMeta,
  }
}
