<script setup lang="ts">
import type { AtomsToggleProps } from './AtomsToggle.props'

const props = defineProps<AtomsToggleProps>()

const emit = defineEmits<{
  onSelected: [boolean]
}>()

const selected = ref(props.selected)

const onToggle = (toggle: boolean) => {
  emit('onSelected', toggle)
  selected.value = toggle
}
</script>

<template>
  <div class="flex">
    <button
      class="cta text-book-8 group flex h-8 w-12 gap-2 border-0 px-2 py-3"
      :class="[selected ? 'selected' : 'default']"
      @click="onToggle(true)"
    >
      {{ textOnButton }}
    </button>
    <button
      v-if="showOffButton"
      class="cta text-book-8 group flex h-8 w-12 gap-2 border-0 px-2 py-3"
      :class="[selected === false ? 'selected' : 'default']"
      @click="onToggle(false)"
    >
      {{ textOffButton }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.default {
  @apply text-text-inactive bg-background-deselected;
}
.selected {
  @apply text-text-primary bg-background-off border-stroke-primary border-b;
}
</style>
