import { joinURL, withTrailingSlash, withLeadingSlash } from 'ufo'
export const useLocalePath = (r?: any) => {
  if (useRuntimeConfig().public.isFederated) {
    const { countryLocale } = useCountryLocale()
    return (path: string) => {
      const finalUrl = joinURL(countryLocale.value, path)
      return `${finalUrl}`
    }
  }
  const route = r ?? useRoute()
  const countryLocale = route.params?.locale ? `/${route.params.locale}` : '/'
  return (path: string) => {
    if (typeof path === 'string' && path.startsWith('http')) return path
    const leadPath = withLeadingSlash(path)
    if (leadPath.startsWith(countryLocale)) return leadPath // already localized
    const finalUrl = joinURL(countryLocale, path)
    return withTrailingSlash(`${finalUrl}`)
  }
}
