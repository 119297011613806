export const useLogin = () => {
  //REGISTRATION METHODS
  const { registrationUser, cleanRegistrationData } = useRegistration()
  const { emailExists, login, loginError } = useAuth0()
  const { dispatchAccountLogoutEvent } = useGAAccountEvents()

  const { resetCart } = useCart()
  const { loggedIn, clear } = useUserSession()
  const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)
  const isLoggedFederated = useState('isLoggedFederated', () => false)
  const isLogged = computed(() => {
    if (isFederatedApp.value) {
      return isLoggedFederated.value
    }
    return loggedIn.value && import.meta.client ? loggedIn.value : false
  })

  // CONST
  const LOGIN_FORM_ID = 'login-form-id'
  const LOGIN_FORM_ID_SIGNUP_PAGE = 'login-form-id-signup-page'
  const LOGIN_EMAIL_STATE = 'LOGIN_EMAIL_STATE'
  const LOGIN_EMAIL_VALID_STATE = 'LOGIN_EMAIL_VALID_STATE'
  const LOGIN_PSW_STATE = 'LOGIN_PSW_STATE'
  const EMAIL_EXIST_0N_AUTH0_STATE = 'EMAIL_EXIST_0N_AUTH0_STATE'
  const EMAIL_EXIST_0N_AUTH0_SALESFORCE = 'EMAIL_EXIST_0N_AUTH0_SALESFORCE'
  const EMAIL_VERIFIED_STATE = 'EMAIL_VERIFIED_STATE'
  const IS_REGISTRATION_STATE = 'IS_REGISTRATION_STATE'
  const LOGIN_SOCIAL_ACCOUNT_CONNECTION = 'LOGIN_SOCIAL_ACCOUNT_CONNECTION'

  const loginEmail = useState<string>(LOGIN_EMAIL_STATE, () => '')
  const loginEmailIsValid = useState<boolean>(
    LOGIN_EMAIL_VALID_STATE,
    () => false
  )
  const loginPassword = useState<string>(LOGIN_PSW_STATE, () => '')
  const emailExistOnAuth0 = useState<boolean>(
    EMAIL_EXIST_0N_AUTH0_STATE,
    () => false
  )
  const emailExistOnSF = useState<boolean>(
    EMAIL_EXIST_0N_AUTH0_SALESFORCE,
    () => false
  )
  const emailVerified = useState<boolean>(EMAIL_VERIFIED_STATE, () => false)
  const isRegistration = useState<boolean>(IS_REGISTRATION_STATE, () => false)
  const socialLoginConnection = useState<string>(
    LOGIN_SOCIAL_ACCOUNT_CONNECTION,
    () => ''
  )

  const loginCheckEmail = async (email: string, showLoader = true) => {
    loginCleanStatus()
    const { checkTokenReCAPTCHA } = useRecaptchaSite()

    try {
      await checkTokenReCAPTCHA('loginCheckEmail')
      // Move to useAuth
      const { userOrFail, sfExists, verified, socialAccountConnection } =
        await emailExists(email, showLoader)

      handleSFExists(sfExists)
      handleUserOrFail(userOrFail, verified, email, socialAccountConnection)

      return emailExistOnAuth0.value
    } catch (error) {
      // Handle any errors
      console.error(error)
      // You can add additional error handling logic here
      return false
    }
  }

  const handleSFExists = (sfExists: boolean) => {
    if (sfExists) {
      emailExistOnSF.value = true
    }
  }

  const handleUserOrFail = (
    userOrFail: boolean,
    verified: boolean,
    email: string,
    socialConnection: string
  ) => {
    if (userOrFail && verified) {
      handleUserVerified(socialConnection)
    } else {
      handleUserNotVerified(email)
    }
  }

  const handleUserVerified = (socialConnection: string) => {
    emailExistOnAuth0.value = true
    emailVerified.value = true
    isRegistration.value = true
    socialLoginConnection.value = socialConnection

    if (isRegistration.value) {
      isRegistration.value = false
      cleanRegistrationData()
    }
  }

  const handleUserNotVerified = (email: string) => {
    emailExistOnAuth0.value = false
    emailVerified.value = false
    isRegistration.value = true
    registrationUser.value.email = email
  }

  const setLoginEmail = (newEmail: string) => (loginEmail.value = newEmail)

  const loginCleanStatus = () => {
    socialLoginConnection.value = ''
    emailExistOnAuth0.value = false
    emailExistOnSF.value = false
    emailVerified.value = false
    loginEmailIsValid.value = false
    isRegistration.value = false
  }

  const loginCleanCredentials = () => {
    loginEmail.value = ''
    loginPassword.value = ''
    loginCleanStatus()
  }

  const logout = async ({ noReload = false }: { noReload?: boolean } = {}) => {
    resetCart()
    await clear()
    if (noReload) return
    window.location.reload()
    dispatchAccountLogoutEvent()
  }

  return {
    LOGIN_FORM_ID,
    LOGIN_FORM_ID_SIGNUP_PAGE,
    loginEmail,
    emailExistOnSF,
    loginEmailIsValid,
    isRegistration,
    emailExistOnAuth0,
    emailVerified,
    loginPassword,
    isLogged,
    loginError,
    socialLoginConnection,
    isLoggedFederated,
    loginCheckEmail,
    loginCleanCredentials,
    setLoginEmail,
    login,
    logout,
  }
}
