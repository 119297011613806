import z from 'zod'
import {
  itemSchema,
  views,
  binaryChoiceSchema,
  ecommerceSchema,
} from '@integration-layer/schemas/GAEvents'

export const CartViews = views.extract(['mini_cart', 'cart_page'])
export const cartItemSchema = itemSchema.extend({
  item_client_service: binaryChoiceSchema,
})

export const ecommerceCartSchema = ecommerceSchema.extend({
  items: z.array(cartItemSchema),
})

export const viewCartSchema = z.object({
  event: z.literal('view_cart'),
  eventID: z.literal('006'),
  ecommerce: ecommerceCartSchema.extend({
    item_list_id: z.string(),
    item_list_name: z.string(),
    view_type: CartViews,
  }),
})

export const addToCartSchema = z.object({
  event: z.literal('add_to_cart'),
  eventID: z.literal('007'),
  ecommerce: ecommerceCartSchema.extend({ view_type: views }),
})

export const removeFromCartSchema = z.object({
  event: z.literal('remove_from_cart'),
  eventID: z.literal('008'),
  ecommerce: ecommerceCartSchema.extend({ view_type: CartViews }),
})
