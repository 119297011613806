//NOTE
// This should be a single named route middleware but it's used as global at the moment.
// When it is used as not global, as value of middleware field in definePageMeta it shows error

export default defineNuxtRouteMiddleware(to => {
  const { guestWishlist, wishlist, getCanonicalUrl } = useRouteHelper()
  const { loggedIn: isLogged } = useUserSession()

  if (
    (to.path === guestWishlist || to.path === guestWishlist + '/') &&
    isLogged.value
  ) {
    const path = getCanonicalUrl(wishlist)
    navigateTo(path)
  }
})
