import { productSchema as algoliaProductSchema } from '@integration-layer/.nuxt/armani/product-schema'
import { z } from 'zod'

export const useAlgoliaProducts = () => {
  const algoliaIndex = useAppConfig().currentAlgoliaIndex

  const loadAlgoliaProducts = async (productCodes: string[]) => {
    const algoliaData = await $fetch(`/api/getProducts/${algoliaIndex}`, {
      query: {
        mfcList: productCodes.join(','),
      },
    })
    const algoliaProducts = algoliaData.filter(isNonNullable)
    return indexByObjectId(algoliaProducts)
  }

  const indexByObjectId = (products: z.infer<typeof algoliaProductSchema>[]) =>
    products.reduce(
      (productsByObjectId, product) => ({
        ...productsByObjectId,
        [product.objectID]: product,
      }),
      {} as Record<string, z.infer<typeof algoliaProductSchema>>
    )

  return {
    loadAlgoliaProducts,
  }
}
