export const useSizebay = async () => {
  const config = useRuntimeConfig()
  const sizeBayId = config.public.sizebay
  if (!sizeBayId) return
  const sizebayScript: string = `https://static.sizebay.technology/${sizeBayId}/prescript.js`

  return useScript({
    src: sizebayScript,
  })
}
