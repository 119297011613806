export const useAuth0 = () => {
  const { dispatchAccountLoginEvent } = useGAAccountEvents()
  const isLoading = useLoaderState()

  const config = useRuntimeConfig()
  const loginError = useState<string | null | undefined>(
    'auth0-login-error',
    () => null
  )
  const currentPageBeforeLogin = useCookie('page-before-login', {
    domain: '.armani.com',
  })
  currentPageBeforeLogin.value = window ? window.location.href : ''
  const { registerSuccessful } = useRouteHelper()

  let currentDomain = ''
  try {
    const url = currentPageBeforeLogin?.value || ''
    const urlObject = new URL(url)
    currentDomain = urlObject.protocol + '//' + urlObject.host || ''
  } catch {
    currentDomain = ''
  }

  const redirectUri =
    currentDomain !== ''
      ? `${currentDomain}/api/auth0/authorize`
      : config.public.auth0.redirectUri

  // @see https://github.com/auth0/auth0.js
  const opts = {
    domain: config.public.auth0.domain,
    clientID: config.public.auth0.clientID,
    redirectUri: redirectUri,
    scope:
      'openid profile email read:current_user update:current_user_metadata create:current_user_metadata delete:current_user_metadata',
    audience: config.public.auth0.audience,
  }

  const emailExists = async (email: string, showLoader = true) => {
    if (showLoader) isLoading.value = true
    try {
      const data = await $fetch('/api/auth0/checkEmailExists', {
        method: 'post',
        body: { email },
      })
      return data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      if (showLoader) isLoading.value = false
    }
  }

  const getSFUserFromEmail = async (email: string) => {
    try {
      const data = await $fetch('/api/auth0/getSfUser', {
        method: 'POST',
        body: { email },
      })
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const login = async (
    username: string,
    password?: string,
    newUser?: boolean // parameter that is only needed when a user registers for go to page registration successful
  ) => {
    const auth0 = await import('auth0-js')
    const webAuth = new auth0.WebAuth(opts)

    // could be called without password
    if (!password) return
    // set current page cookie
    const currentPageBeforeLogin = useCookie('page-before-login')
    currentPageBeforeLogin.value = window
      ? newUser
        ? registerSuccessful
        : window.location.href
      : ''

    if (newUser) {
      const successfulRegistered = useCookie('successfulRegistered')
      successfulRegistered.value = 'successfulRegistered'
    } else {
      // Store a flag for registration event
      localStorage.setItem('pendingLoginEvent', 'true')
    }

    loginError.value = null

    // @see https://github.com/auth0/auth0.js

    webAuth.login(
      {
        email: username,
        password: password,
        realm: 'Username-Password-Authentication',
        scope:
          'offline_access openid profile email read:current_user update:current_user_metadata create:current_user_metadata delete:current_user_metadata',
        audience: `https://${config.public.auth0.audienceDomain}/api/v2/`,
        responseType: 'code',
      },
      e => {
        console.log(e)
        loginError.value = e?.error_description || e?.error
      }
    )
  }

  const checkPsw = async (username: string, password?: string) => {
    if (!password) return

    const data = await $fetch(`/api/auth0/getTokenPswGrant`, {
      method: 'POST',
      body: { username, password },
    })
    return data
  }

  const socialLogin = async (connection: string) => {
    const currentPageBeforeLogin = useCookie('page-before-login')
    currentPageBeforeLogin.value = window ? window.location.href : ''

    const redirectUri =
      currentDomain !== ''
        ? `${currentDomain}/api/auth0/authorizeSocialLogin`
        : config.public.auth0.redirectSocialUri

    const auth0 = await import('auth0-js')

    const webAuth = new auth0.WebAuth({
      domain: opts.domain,
      clientID: opts.clientID,
      redirectUri: redirectUri,
    })

    webAuth.authorize({
      connection: connection,
      responseType: 'code',
      scope:
        'openid profile email read:current_user update:current_user_metadata create:current_user_metadata delete:current_user_metadata',
      audience: `https://${config.public.auth0.audienceDomain}/api/v2/`,
    })
  }

  onMounted(() => {
    const pendingLoginEvent = localStorage.getItem('pendingLoginEvent')

    if (pendingLoginEvent === 'true') {
      dispatchAccountLoginEvent()
      localStorage.removeItem('pendingLoginEvent')
    }
  })

  return {
    emailExists,
    login,
    loginError,
    checkPsw,
    socialLogin,
    getSFUserFromEmail,
  }
}
