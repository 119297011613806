const screens = {
  sm: `375px`,
  md: `768px`,
  ml: `1024px`,
  lg: `1024px`,
  'lg-custom': '1280px',
  xl: `1920px`,
}

export default screens

export type Breakpoints = keyof typeof screens
