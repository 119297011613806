<script lang="ts">
import snarkdown from 'snarkdown'
// NB: Tables are not yet supported in snarkdown.

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    container: {
      type: String,
      default: 'div',
    },
    isPreprocessed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const localePath = useLocalePath()

    const preprocessContent = (content: string) => {
      if (!content) return ''

      // Convert [lang=en](word) to <span lang="xx">word</span>
      let processedContent = content?.replace(
        /(?:\[lang=([a-z]{2})\]\((.*?)\)|\\\[lang=([a-z]{2})\\\]\((.*?)\))/gi,
        (match, p1, p2, p3, p4) => {
          const lang = p1 || p3
          const word = p2 || p4
          return `<span lang="${lang}">${word}</span>`
        }
      )

      // Handle links that start with "/"
      processedContent = processedContent?.replace(
        /\[([^\]]+)\]\((\/[^\)]+)\)/g,
        (match, text, link) => {
          return `<a href="${localePath(link)}">${text}</a>`
        }
      )

      // Convert lines starting with 2 spaces and a dash to nested lists
      processedContent = processedContent.replace(
        /(?:^|\n) {2}-\s+(.+?)(?=\n|$)/g,
        (match, p1) => {
          return `<ul><li>${p1.trim()}</li></ul>`
        }
      )

      // Convert lines starting with 2 spaces and a number followed by a dot to ordered lists
      processedContent = processedContent.replace(
        /(?:^|\n) {2}(\d+)\.\s+(.+?)(?=\n|$)/g,
        (match, p1, p2) => {
          return `<br/><div style="padding-left: 1.25rem" >${p1}. ${p2.trim()}</div>`
        }
      )

      // processedContent = processedContent.replace(
      //   /####\s+(.*)/gm,
      //   '<h4>$1</h4>'
      // )
      // processedContent = processedContent.replace(/###\s+(.*)/gm, '<h3>$1</h3>')
      // processedContent = processedContent.replace(/##\s+(.*)/gm, '<h2>$1</h2>')
      processedContent = processedContent.replace(/\s#\s+(.*)/gm, '<h1>$1</h1>')

      // Convert Markdown-style tables to HTML tables
      const tableRows = processedContent.match(/^\|(.+?)\|$/gm)
      if (tableRows) {
        // Create headers from the first row
        const headers = tableRows[0]
          .split('|')
          .slice(1, -1)
          .map(cell => `<th>${cell.trim()}</th>`)
          .join('')

        // Create body rows
        const bodyRows = tableRows
          .slice(2)
          .map(row => {
            return row
              .split('|')
              .slice(1, -1)
              .map(cell => `<td><p>${cell.trim()}</p></td>`)
              .join('')
          })
          .join('</tr><tr style="display:table-row">')

        processedContent = processedContent.replace(
          tableRows.join('\n'),
          `<table style="display:table"><thead><tr style="display:table-row">${headers}</tr></thead><tbody style="display:table-row-group"><tr style="display:table-row">${bodyRows}</tr></tbody></table>`
        )
      }

      // Remove three consecutive spaces
      processedContent = processedContent?.replace(/ {3,}/g, '')

      processedContent = processedContent.replace(/\\n/g, '<br/>')
      // After handling lists, convert any <br/> and <u>
      if (!props.isPreprocessed) return processedContent
      processedContent = processedContent.replace(/__(.*?)__/g, '<u>$1</u>')

      return processedContent
    }

    const md = ref(props?.content)
    const preprocessedContent = preprocessContent(md?.value)
    let html = snarkdown(preprocessedContent)
    // This is to add *, because snarkdown removes it
    html = html.replace(/\\\*(?=[^*]|$)/g, '*')

    return () =>
      h(props?.container ?? 'div', {
        innerHTML: html,
      })
  },
})
</script>
