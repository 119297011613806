export default {
  "cart-layout": () => import("/opt/build/repo/packages/global/layouts/cartLayout.vue"),
  checkout: () => import("/opt/build/repo/packages/global/layouts/checkout.vue"),
  "customer-service-layout": () => import("/opt/build/repo/packages/global/layouts/customerServiceLayout.vue"),
  default: () => import("/opt/build/repo/packages/global/layouts/default.vue"),
  "legal-area-layout": () => import("/opt/build/repo/packages/global/layouts/legalAreaLayout.vue"),
  "my-account-layout": () => import("/opt/build/repo/packages/global/layouts/myAccountLayout.vue"),
  previewlayout: () => import("/opt/build/repo/packages/global/layouts/previewlayout.vue"),
  "signup-in-page": () => import("/opt/build/repo/packages/global/layouts/signupInPage.vue"),
  "static-page-layout": () => import("/opt/build/repo/packages/global/layouts/staticPageLayout.vue")
}