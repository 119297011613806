import type {
  Cookie,
  SelectedCookie,
} from '@design-system/components/Cookie/CookiebannerCenter.type'

declare global {
  interface Window {
    OneTrust: OneTrust
  }
}

type OneTrust = {
  GetDomainData: () => {
    Groups: OneTrustCookie[]
  }
  RejectAll: () => void
  AllowAll: () => void
  IsAlertBoxClosedAndValid: () => boolean
  changeLanguage: (language: string) => void
  UpdateConsent: (type: string, cookies: string) => void
}

type OneTrustCookie = {
  OptanonGroupId: string
  GroupName: string
  GroupDescription: string
  Status: 'always active' | 'active' | 'inactive'
  Cookies: any[]
}

const notLoadCookieBanner = [
  'en-au',
  'fr-be',
  'en-ca',
  'es-cl',
  'en-hk',
  'en-id',
  'en-il',
  'ja-jp',
  'ko-kr',
  'es-mx',
  'en-my',
  'en-nz',
  'en-ph',
  'en-sg',
  'en-th',
  'en-tr',
  'en-tw',
  'en-ua',
  'en-wx',
  'en-za',
]

const notShowCookieBanner = ['en-us', 'es-us']

const notShowOrLoadCookieBanner = [
  ...notLoadCookieBanner,
  ...notShowCookieBanner,
]

export const useOneTrustCookies = () => {
  // for federated app support
  const isLoaded = useState('is_loaded_cookie', () => false)
  const { eventBus } = useFederatedEventBus()
  const { countryLocale } = useCountryLocale()
  const { country } = useRouteHelper()

  const shouldLoad = computed(
    () =>
      !!countryLocale.value &&
      !notLoadCookieBanner.includes(countryLocale.value)
  )

  if (shouldLoad.value) {
    useScript(
      {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script':
          country.toLowerCase() === 'us'
            ? '0192b849-42e6-7972-b5cf-8308303c610c'
            : '01919d3c-be13-74ce-a59e-ed8763aa05ee',
        async: true,
      },
      {
        trigger: 'server',
      }
    )
  }

  let oneTrust = import.meta.client ? window.OneTrust : undefined
  const checkOneTrust = () => {
    oneTrust = import.meta.client ? window.OneTrust : undefined
  }

  const { language } = useRouteHelper()
  const { openDialog: openCookieBanner } = useDialog('modal-cookie-banner')
  const cookies = useState<Cookie[]>('ONE_TRUST_COOKIES', () => [])

  const allowedCookies = computed<SelectedCookie[]>(() =>
    cookies.value.map(cookie => ({
      id: cookie.id,
      selected: cookie.selected ?? false,
    }))
  )

  const checkSavedConsent = () => {
    const cookieValue = useCookie('OptanonConsent').value
    if (cookieValue) {
      const cookieParams = new URLSearchParams(cookieValue)

      const groups = cookieParams.get('groups')

      if (groups) {
        const groupPairs = groups.split(',').map(group => {
          const [id, value] = group.split(':')
          return { id, value: parseInt(value) }
        })

        return groupPairs
      }
    }
  }

  const loadScript = async () => {
    if (!shouldLoad.value) {
      return
    }

    await waitUntil(
      () => !!window.OneTrust,
      () => {
        isLoaded.value = true
        checkOneTrust()
        const oneTrustCookie = oneTrust!
          .GetDomainData()
          .Groups.filter(data => data.Cookies.length)
        cookies.value = oneTrustCookie.map(cookie => ({
          id: cookie.OptanonGroupId,
          title: cookie.GroupName,
          text: cookie.GroupDescription,
          offButton: cookie.Status !== 'always active',
          defaultSelected: cookie.Status === 'always active' ? true : undefined,
          selected: cookie.Status === 'always active' ? true : undefined,
        }))
        oneTrust!.changeLanguage(language)
        if (
          !oneTrust!.IsAlertBoxClosedAndValid() &&
          !notShowOrLoadCookieBanner.includes(countryLocale.value)
        ) {
          openCookieBanner()
        } else {
          eventBus?.$emit('privacyAcceptanceEvent', true)
          const savedCookies = checkSavedConsent()
          if (savedCookies?.length) {
            savedCookies.forEach(cookieSaved => {
              const item = cookies.value.find(
                cookie => cookie.id === cookieSaved.id
              )
              item &&
                item.offButton === true &&
                (item.selected = cookieSaved.value === 1)
            })
          }
        }
      },
      100,
      10_000
    )
  }

  const toggleCookie = (id: string, selected: boolean) => {
    cookies.value = cookies.value.map(cookie =>
      cookie.id === id ? { ...cookie, selected } : cookie
    )
  }

  const save = () => {
    const oneTrustConsentCookies = allowedCookies.value
      .map(item => `${item.id}:${item.selected ? 1 : 0}`)
      .join(',')

    checkOneTrust()
    oneTrust?.UpdateConsent?.('Category', oneTrustConsentCookies)
  }

  const acceptAll = () => {
    cookies.value.forEach(cookie => (cookie.selected = true))
    checkOneTrust()
    eventBus?.$emit('privacyAcceptanceEvent', true)
    oneTrust?.AllowAll?.()
  }

  const rejectAll = () => {
    cookies.value.forEach(cookie => {
      if (cookie.offButton) {
        cookie.selected = false
      }
    })
    checkOneTrust()
    eventBus?.$emit('privacyAcceptanceEvent', false)
    oneTrust?.RejectAll?.()
  }

  const changeCookieLanguage = (language: string) => {
    checkOneTrust()
    oneTrust?.changeLanguage?.(language)
  }

  return {
    cookies,
    rejectAll,
    acceptAll,
    changeCookieLanguage,
    toggleCookie,
    save,
    isLoaded,
    notLoadCookieBanner,
    loadScript,
  }
}
