import {
  getConfigByCountry,
  getCountryFromUrl,
  getCountryAndLanguage,
  getLanguageFromUrl,
} from '@integration-layer/integrationConfig'
import { marketSettings } from '@integration-layer/data/armani/markets'

export default defineNuxtRouteMiddleware(to => {
  const config = getConfigByCountry(to)
  const language = getLanguageFromUrl(to.fullPath)
  const countyAndLanguage = getCountryAndLanguage(to.fullPath)

  const currentLangConfig = config.languages.find(
    ({ langcode }) => langcode === language
  )
  const currentAlgoliaIndex = currentLangConfig?.algolia_index || ''

  const currentVisenzeConfig = {
    app_key: currentLangConfig?.visenze_app_key ?? '',
    pdp_vsr: currentLangConfig?.visenze_pdp_vsr ?? '',
    pdp_stl: currentLangConfig?.visenze_pdp_stl ?? '',
    vsearch_key: currentLangConfig?.visenze_vsearch_app_key ?? '',
    vsearch_id: currentLangConfig?.visenze_vsearch ?? '',
  }

  const currentAlgoliaLookbookIndex =
    config.languages.find(({ langcode }) => langcode === language)
      ?.lookbook_index || ''

  const market =
    marketSettings[config.cl_market_code?.toLowerCase() ?? 'default']

  const algoliaPrefix = useRuntimeConfig().public.algoliaPrefix

  useAppConfig().config = config
  useAppConfig().currentAlgoliaIndex = `${algoliaPrefix ?? ''}${currentAlgoliaIndex.toLowerCase()}`
  useAppConfig().currentVisenzeConfig = currentVisenzeConfig
  useAppConfig().currentAlgoliaLookbookIndex = `${algoliaPrefix ?? ''}${currentAlgoliaLookbookIndex.toLowerCase()}`
  useAppConfig().currentLanguage = language
  useAppConfig().currentCountry = getCountryFromUrl(to.fullPath)
  useAppConfig().currentLanguageCountry = countyAndLanguage
  useAppConfig().currentMarketSettings = market
})
