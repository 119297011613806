
import * as cloudinaryRuntime$rHG6y6GR1p from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as netlifyRuntime$2xGLoGD2LP from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'
import * as myProviderRuntime$2TG3ILtcfE from '/opt/build/repo/packages/design-system/providers/cloudinaryVideoImg.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 375,
    "md": 768,
    "lg": 1024,
    "xl": 1920,
    "xxl": 1536,
    "2xl": 1536,
    "ml": 1024
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2,
    3,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$rHG6y6GR1p, defaults: {"baseURL":"https://assets.armani.com/image/upload/","maxAge":8380800} },
  ['netlify']: { provider: netlifyRuntime$2xGLoGD2LP, defaults: {} },
  ['cloudinaryVideoImg']: { provider: myProviderRuntime$2TG3ILtcfE, defaults: {"baseURL":"https://assets.armani.com/video/upload/","maxAge":8380800} }
}
        