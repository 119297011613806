import type { GASetupDatalayerParamPage } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import snakeCase from 'lodash/snakeCase'
import type { ProductSchema as AlgoliaProductType } from '@integration-layer/.nuxt/armani/product-schema'

export const useParamPageItem = (algoliaProduct: AlgoliaProductType) => {
  const { country } = useRouteHelper()

  const getGender = () => {
    const isWoman = algoliaProduct.categoryPageId2?.[1].includes('woman')
    const isMan = algoliaProduct.categoryPageId2?.[1].includes('man')

    return isWoman ? 'D' : isMan ? 'U' : 'E'
  }
  const gender = getGender()

  const department = `${country.toUpperCase()}_${algoliaProduct.SizeGridCode}`
  const brand = snakeCase(algoliaProduct.Brand)
  const mainCategoryFirstLetterCapitalized = capitalizeFirstLetter(
    algoliaProduct.mainCategory?.name ?? ''
  )

  const paramPage: GASetupDatalayerParamPage = {
    page_type: 'item',
    brand,
    department,
    gender,
    page_status_code: 200,
    sub_page_type: `${department}_${mainCategoryFirstLetterCapitalized}`,
  }
  return paramPage
}
