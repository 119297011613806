export const mapShortWebsitesAlias = (website: string) => {
  switch (website) {
    case 'giorgio-armani':
      return 'GA'
    case 'emporio-armani':
      return 'EA'
    case 'armani-exchange':
      return 'AX'
    case 'ea7':
      return 'EA7'
    default:
      return 'GA'
  }
}
