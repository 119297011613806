export default defineNuxtRouteMiddleware((to, from) => {
  const { checkoutUrl, cartUrl } = useRouteHelper()
  const isFromCheckoutToCart = useState<boolean>(
    'fromCheckoutToCart',
    () => false
  )

  if (from.path === checkoutUrl && to.path === cartUrl) {
    isFromCheckoutToCart.value = true
  } else {
    isFromCheckoutToCart.value = false
  }
})
