export type Auth = {
  organization: string
  getToken: () => Promise<string>
  refreshToken?: () => Promise<string>
}

// Function to create a CommerceLayer client
export const createCommerceLayerClient = async (auth: Auth) => {
  const { CommerceLayer } = await import('@commercelayer/sdk')
  const cl = CommerceLayer({
    organization: auth.organization,
    // fetch the token only when needed
    accessToken: 'temp',
    refreshToken: auth.refreshToken,
  })

  // Intercept each request to add the current access token to the headers
  cl.addRequestInterceptor(async config => {
    const token = await auth.getToken()

    return {
      ...config,
      options: {
        ...config.options,
        headers: {
          ...Object.fromEntries(new Headers(config.options.headers).entries()),
          authorization: `Bearer ${token}`,
        },
      },
    }
  })

  return cl
}
