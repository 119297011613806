import type { Order } from '@commercelayer/sdk'
import type { Views } from '@integration-layer/schemas/GAEvents'
import {
  addToCartSchema,
  removeFromCartSchema,
  viewCartSchema,
} from '@integration-layer/schemas/GAEvents/cart'

type CartViews = Extract<Views, 'mini_cart' | 'cart_page'>
type ViewCartParams = { view: CartViews; cart?: Order }
type AddToCartParams = { view: Views; addedFromChat?: boolean }
type RemoveFromCartParams = {
  view: CartViews
  qty?: number
  removedFromChat?: boolean
}

export const useGACartEvents = () => {
  const debug = !!useRuntimeConfig().public.ga4.debug
  const { cartEventsPayload, getEcommerceData, setCartEventsPayload } =
    useGAEcommerceData()

  const dispatchViewCartEvent = async (
    { view, cart }: ViewCartParams,
    options?: { waitForDatalayerSetup?: boolean }
  ) => {
    if (cart) setCartEventsPayload({ cart })
    const ecommerce = await getEcommerceData(false)
    const showItemList = computed(() =>
      ecommerce?.items.length === 1 ? ecommerce.items[0].item_list_name : ''
    )

    if (!ecommerce) return
    useGASendEvent(
      viewCartSchema,
      {
        event: 'view_cart',
        eventID: '006',
        ecommerce: {
          item_list_id: '',
          item_list_name: showItemList.value,
          view_type: view,
          ...ecommerce,
        },
      },
      {
        clearEcommerce: true,
        debug,
        waitForDatalayerSetup: options?.waitForDatalayerSetup,
      }
    )
    cartEventsPayload.value = null
  }

  const dispatchAddToCartEvent = async ({
    view,
    addedFromChat = false,
  }: AddToCartParams) => {
    const ecommerce = await getEcommerceData(addedFromChat, 1)
    if (!ecommerce) return
    useGASendEvent(
      addToCartSchema,
      {
        event: 'add_to_cart',
        eventID: '007',
        ecommerce: {
          view_type: view,
          ...ecommerce,
        },
      },
      { clearEcommerce: true, debug }
    )
    cartEventsPayload.value = null
  }

  const dispatchRemoveFromCartEvent = async ({
    view,
    qty,
    removedFromChat = false,
  }: RemoveFromCartParams) => {
    const ecommerce = await getEcommerceData(removedFromChat, qty)
    if (!ecommerce) return
    useGASendEvent(
      removeFromCartSchema,
      {
        event: 'remove_from_cart',
        eventID: '008',
        ecommerce: {
          view_type: view,
          ...ecommerce,
        },
      },
      { clearEcommerce: true, debug }
    )
    cartEventsPayload.value = null
  }

  return {
    cartEventsPayload,
    setCartEventsPayload,
    dispatchViewCartEvent,
    dispatchAddToCartEvent,
    dispatchRemoveFromCartEvent,
  }
}
