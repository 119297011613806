// This middleware checks if the user has successfully completed registration.
// If the user tries to access the 'registerSuccessful' route without completing registration, they will be redirected to the home page.
export default defineNuxtRouteMiddleware(to => {
  const { registerSuccessful, homePath, getCanonicalUrl } = useRouteHelper()
  const storedValue = useCookie('successfulRegistered')
  if (
    (to.path === registerSuccessful || to.path === registerSuccessful + '/') &&
    storedValue.value !== 'successfulRegistered'
  ) {
    const path = getCanonicalUrl(homePath)
    return navigateTo(path)
  }
})
