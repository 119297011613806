import type { Sku } from '@commercelayer/sdk'
import type { ProductSchema } from 'integration-layer/.nuxt/armani/product-schema'
import type { MyAccountWishlistProductCardProps } from '@design-system/components/MyAccount/Wishlist/MyAccountWishlistProductCard.props'
import type { Brand } from '@design-system/index'
import { wishlistStorageItemSchema } from '@integration-layer/schemas/wishlist'
import { countries } from '@design-system/configs/countries'
import { z } from 'zod'
import kebabCase from 'lodash/kebabCase'

export type WishlistItem = MyAccountWishlistProductCardProps
export type WishlistStorageItem = z.infer<typeof wishlistStorageItemSchema>
export type WishlistStorage = {
  get: () => Promise<WishlistStorageItem[]>
  createOrUpdate: (items: WishlistStorageItem[]) => Promise<void>
  delete: () => Promise<void>
}

type DiffItemsResult = {
  removedItem: WishlistItem | null
  updatedItems: WishlistItem[]
}

export const authWishlistStorage = {
  get: async () => {
    const wishlist =
      (await $fetch<WishlistStorageItem[]>('/api/wishlist')) ?? []

    return wishlist.filter(
      (item: unknown): item is WishlistStorageItem =>
        wishlistStorageItemSchema.safeParse(item).success
    )
  },
  createOrUpdate: (items: WishlistStorageItem[]) =>
    $fetch('/api/wishlist', {
      method: 'post',
      body: {
        wishlists: items,
      },
    }),
  delete: () =>
    $fetch('/api/wishlist', {
      method: 'delete',
    }),
}

export const guestWishlistStorage = {
  get: async (): Promise<WishlistStorageItem[]> => {
    const stringifiedWishlist = localStorage.getItem('wishlist')
    if (!stringifiedWishlist) return []
    try {
      const wishlist = JSON.parse(stringifiedWishlist)
      return wishlist.filter(
        (item: unknown): item is WishlistStorageItem =>
          wishlistStorageItemSchema.safeParse(item).success
      )
    } catch (err) {
      console.error(err)
      return []
    }
  },
  createOrUpdate: async (items: WishlistStorageItem[]) =>
    localStorage.setItem('wishlist', JSON.stringify(items)),
  delete: async () => localStorage.removeItem('wishlist'),
}

export const isInWishlist = (
  wishlist: WishlistStorageItem[],
  productCode: string
) =>
  wishlist.some(
    wishlistItem =>
      (wishlistItem.size?.skuCode ?? wishlistItem.productCode) === productCode
  )

export const diffWishlists = (
  guestWishlist: WishlistStorageItem[],
  loggedWishlist: WishlistStorageItem[]
): WishlistStorageItem[] =>
  guestWishlist.filter(item => {
    const productCode = item.size?.skuCode ?? item.productCode
    return !isInWishlist(loggedWishlist, productCode)
  })

export const diffItems = (items: WishlistItem[], productCode: string) =>
  items.reduce<DiffItemsResult>(
    (acc, item) => {
      if (item.productCode === productCode) {
        acc.removedItem = item
        return acc
      }
      acc.updatedItems.push(item)
      return acc
    },
    { removedItem: null, updatedItems: [] }
  )

export const formatBrand = (brand: string) => {
  brand = brand.toLowerCase()
  // lodash threats numbers as separate words, so ea7 would be parsed in ea-7
  return (brand === 'ea7' ? brand : kebabCase(brand)) as Brand
}

export const mapStorageItems = (
  items: WishlistItem[],
  country: string
): WishlistStorageItem[] =>
  items.map(item => ({
    productCode: item.productCode,
    website: formatBrand(item.productBrand),
    ...(item.productSku && item.selectedSize
      ? {
          size: {
            skuCode: item.productSku,
            value: item.selectedSize,
            country: item.selectedCountry ?? '',
          },
          notifyMe: true,
        }
      : {}),
    country,
  }))

export const mapWishlistItems = (
  wishlist: WishlistStorageItem[],
  algoliaProducts: ProductSchema[],
  skus: Sku[],
  country: string,
  language: string
) =>
  wishlist.flatMap<WishlistItem>(item => {
    const algoliaProduct = algoliaProducts.find(
      algoliaProduct => algoliaProduct?.objectID === item.productCode
    )
    if (!algoliaProduct) return []

    let currency = ''
    const price = (() => {
      const sku = skus.find(
        sku =>
          sku.code ===
          (item.size?.skuCode ? item.size.skuCode : algoliaProduct.size[0].SKU)
      )
      const skuPrice = sku?.prices?.[0]

      const _country = countries.find(({ code }) => code === country)
      currency = skuPrice?.currency_code ?? _country?.cl?.currency ?? 'EUR'
      return skuPrice?.amount_float ?? algoliaProduct.FullPrice
    })()

    const isDiscounted = (() => {
      const sku = skus.find(
        sku =>
          sku.code ===
          (item.size?.skuCode ? item.size.skuCode : algoliaProduct.size[0].SKU)
      )
      return (
        sku?.prices?.[0].amount_float !==
        sku?.prices?.[0].compare_at_amount_float
      )
    })()

    const isAvailable = skus.some(el =>
      item.size?.skuCode
        ? item.size.skuCode === el.code &&
          el.stock_items?.some(item => item.quantity > 0)
        : algoliaProduct.size.find(product => product.SKU === el.code) &&
          el.stock_items?.some(item => item.quantity > 0)
    )

    const lastInStock = skus.some(el =>
      item.size?.skuCode
        ? item.size.skuCode === el.code &&
          !el?.stock_items?.some(item => item.quantity > 1)
        : algoliaProduct.size.some(
            size =>
              size.SKU === el.code &&
              !el?.stock_items?.some(item => item.quantity > 1)
          )
    )

    const categories = extractCategories(algoliaProduct.hierarchicalCategories)

    const selectedImage =
      getHighestPriorityImage(algoliaProduct.assets)?.URL ?? ''

    return {
      productSku: item.size?.skuCode,
      productCode: item.productCode,
      title: algoliaProduct.Name,
      path: getPdpPath(algoliaProduct, language),
      selectedColor: algoliaProduct.ColorDesc ?? '',
      selectedSize: item.size?.value,
      selectedCountry: item.size?.country,
      productBrand: item.website as Brand,
      selectedImage,
      price,
      currency,
      categories,
      isAvailable,
      isDiscounted,
      lastInStock,
      MFC: algoliaProduct?.MFC ?? '',
      notifyMe: !!item.notifyMe,
    }
  })
