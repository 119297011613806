import snakeCase from 'lodash/snakeCase'
import {
  internalSearchButtonSchema,
  internalSearchBrandSchema,
  internalSearchImageSchema,
  internalSearchFilterSchema,
  internalSearchExpandedSchema,
} from '@integration-layer/schemas/GAEvents/internalSearchEvents'

const debug = !!process.env.DEBUG_GA_EVENTS

export const useGAInternalSearchEvents = () => {
  const dispatchInternalSearchButton = () => {
    useGASendEvent(
      internalSearchButtonSchema,
      {
        event: 'GAevent',
        eventID: '013',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: '-',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchInternalSearchBrand = (query: string, brand: string) => {
    useGASendEvent(
      internalSearchBrandSchema,
      {
        event: 'GAevent',
        eventID: '029',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: 'search_brand',
        eventElement01: query,
        eventElement02: snakeCase(brand) === 'ea_7' ? 'EA7' : snakeCase(brand),
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchInternalSearchImage = (query: string) => {
    useGASendEvent(
      internalSearchImageSchema,
      {
        event: 'GAevent',
        eventID: '030',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: 'search_image',
        eventElement01: query,
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchInternalFilter = (
    query: string,
    filter: string,
    collection: string
  ) => {
    useGASendEvent(
      internalSearchFilterSchema,
      {
        event: 'GAevent',
        eventID: '031',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: 'internal_filter',
        eventElement01: query,
        eventElement02: snakeCase(filter),
        eventElement03: snakeCase(collection),
      },
      { debug }
    )
  }
  const dispatchInternalSearchExpansion = (query: string, brand: string) => {
    useGASendEvent(
      internalSearchExpandedSchema,
      {
        event: 'GAevent',
        eventID: '032',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: 'internal_expansion',
        eventElement01: query,
        eventElement02: snakeCase(brand) === 'ea_7' ? 'EA7' : snakeCase(brand),
        eventElement03: '-',
      },
      { debug }
    )
  }

  return {
    dispatchInternalSearchButton,
    dispatchInternalSearchBrand,
    dispatchInternalSearchImage,
    dispatchInternalFilter,
    dispatchInternalSearchExpansion,
  }
}
