export default defineNuxtRouteMiddleware(async (to, from) => {
  // RUN THIS MIDDLEWARE ONLY IN CART AND CHECKOUT PAGES
  if (!to.fullPath.includes('checkout') && !to.fullPath.includes('cart')) return
  if (!process.client) return

  const { alignPromotionsForCart, initCompare, soldOutCompareData } = useCart()

  // not first load and  not if path remain the same but query changes
  if (to && to.path === from.path) return
  // not to cart page with soldOutCompareData
  if (to.fullPath.includes('cart') && soldOutCompareData.value?.length) return

  await alignPromotionsForCart()
  initCompare()
})
