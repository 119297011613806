import type {
  Auth,
  SerializedToken,
  Token,
} from '@integration-layer/configs/commercelayer'
import { isTokenValid } from '@integration-layer/configs/commercelayer'
import { StorageSerializers } from '@vueuse/core'

export const useClAuth = (): Auth => {
  const config = useRuntimeConfig()
  const configs = useConfigs()
  const organization = config.public.commercelayer.organization
  const market = configs.value.cl_market_code!

  const inMemoryTokenPromisePerMarket: Record<
    string,
    Promise<SerializedToken>
  > = {}

  const storage = process.client
    ? useLocalStorage<SerializedToken>(
        `commercelayer:guestToken:${market}`,
        null,
        {
          serializer: StorageSerializers.object,
        }
      )
    : null

  const getToken = async () => {
    const tokenPromise = inMemoryTokenPromisePerMarket[market]
    const token = (await tokenPromise) ?? storage?.value

    if (token && isTokenValid(token)) return token.accessToken

    return await refreshToken()
  }

  const refreshToken = async () => {
    const tokenPromise = $fetch(`/api/getCLGuestToken/${market}`)
    inMemoryTokenPromisePerMarket[market] = tokenPromise
    const newToken = await tokenPromise

    if (storage) {
      storage.value = newToken
    }

    return newToken.accessToken
  }

  return {
    organization,
    getToken,
    refreshToken,
  }
}
