<script setup lang="ts">
import type { UtilsSkipLinkProps } from './UtilsSkipLink.props'

const props = withDefaults(defineProps<UtilsSkipLinkProps>(), {
  targetId: 'main',
})

function handleSkip() {
  const element = document.getElementById(props.targetId || '')
  if (element) {
    element.focus()
  }
}
</script>

<template>
  <a
    v-if="targetId"
    data-testid="skip-link"
    :href="`#${targetId}`"
    class="bg-primitives-black text-primitives-off-white absolute -top-10 left-0 z-[9999] p-2 transition-all duration-300 focus-within:top-0"
    @click="handleSkip"
  >
    {{ $ts('accessibility.skipToContent') }}
  </a>
</template>
