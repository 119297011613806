export const useDialog = (id: string = '') => {
  const _Dialogs = useState('useDialog__dialogs', () => ({}) as any)

  const dialogId = ref(id)

  const isDialogOpen = computed<boolean>(() => {
    return _Dialogs.value[dialogId.value]
  })
  const isDialogOpening = ref(false)

  const isAnyDialogOpen = computed<boolean>(() =>
    Object.values(_Dialogs.value).some(value => value)
  )

  const initDialog = () => {
    if (!dialogId.value) return

    _Dialogs.value[dialogId.value] =
      dialogId.value in _Dialogs.value ? _Dialogs.value[dialogId.value] : false
  }

  const clearDialogs = () => {
    _Dialogs.value = {}
    dialogId.value = ''
  }

  const destroyDialogs = () => {
    if (dialogId.value in _Dialogs.value) {
      delete _Dialogs.value[dialogId.value]
    }
  }

  const destroyDialog = (id?: string) => {
    delete _Dialogs.value[id ?? dialogId.value]
  }

  const switchDialogState = (id: string, state: boolean) => {
    if (id in _Dialogs.value) {
      _Dialogs.value[id] = state
    }
  }

  const openDialog = (id?: string) => {
    if (!isDialogOpening.value) {
      isDialogOpening.value = true
      const dialogToOpen = id ? id : dialogId.value
      // Set the dialog value to true only after the transition ends
      setTimeout(() => {
        Object.keys(_Dialogs.value).includes(dialogToOpen) &&
          switchDialogState(dialogToOpen, true)
        isDialogOpening.value = false
      }, 550)
    }
  }

  const openMenuDialog = (id?: string) => {
    const dialogToOpen = id ? id : dialogId.value
    Object.keys(_Dialogs.value).includes(dialogToOpen) &&
      switchDialogState(dialogToOpen, true)
    isDialogOpening.value = false
  }

  const closeDialog = (id?: string) => {
    const dialogToClose = id ? id : dialogId.value
    Object.keys(_Dialogs.value).includes(dialogToClose) &&
      switchDialogState(dialogToClose, false)
  }

  const closeAllDialogs = () => {
    Object.keys(_Dialogs.value).forEach(dialogId => {
      switchDialogState(dialogId, false)
    })
  }

  initDialog()

  return {
    isDialogOpen,
    isAnyDialogOpen,
    clearDialogs,
    destroyDialogs,
    openDialog,
    openMenuDialog,
    closeDialog,
    destroyDialog,
    closeAllDialogs,
  }
}
