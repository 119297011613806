<template>
  <AtomsLoaderUI v-if="isLoading" />
</template>

<script setup lang="ts">
const isLoading = useLoaderState()
const route = useRoute()
if ((route.name! as string)?.includes('preview')) {
  isLoading.value = true
}
</script>
