export const useCountryLocale = () => {
  const appConfig = useAppConfig()
  const isFederated = useRuntimeConfig().public.isFederated
  // get initial country locale first time the composable get called
  const cookieI18n = useCookie('i18n_redirected')

  const getInitialCLocale = () => {
    if (isFederated) {
      const clocale = window && window.location.pathname.split('/')[1]
      // check that country locale is xx-yyyy
      if (clocale && clocale.length === 5 && clocale.includes('-')) {
        return clocale
      }
      // else, return cookie i18n_redirected
      return cookieI18n.value || 'en-us'
    } else {
      return appConfig.currentLanguageCountry
    }
  }
  const initialCLocale = ref(getInitialCLocale())

  const isSetup = useState('setup_country_locale_federated', () => false)

  const countryLocale = computed(() => {
    if (isFederated && !isSetup.value) {
      return initialCLocale.value
    }
    // if we have appConfig set, we use appconfig value
    const appConfigClocale = appConfig.currentLanguageCountry
    return appConfigClocale
  })

  const country = computed(() => countryLocale.value.split('-')[1])
  const locale = computed(() => countryLocale.value.split('-')[0])

  return {
    countryLocale,
    country,
    locale,
    isSetup,
  }
}
