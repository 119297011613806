export const useDatacloud = async () => {
  const config = useRuntimeConfig()
  const isProd = config.public.isProduction
  if (isProd) return
  const datacloudScript: string = `https://cdn.c360a.salesforce.com/beacon/c360a/946002d5-ee24-40a6-a986-2f2178d3dfbe/scripts/c360a.min.js`

  return useScript({
    src: datacloudScript,
  })
}
