type Asset = {
  URL?: string | undefined
  priority?: number | undefined
  type?: string | undefined
}

export const getHighestPriorityImage = (assets: Asset[] | undefined) => {
  const sortedImages = [...(assets ?? [])].sort(
    (a, b) => (a.priority ?? 0) - (b.priority ?? 0)
  )
  return sortedImages?.[0]
}
