<template>
  <div
    class="bg-primitives-white/80 fixed inset-0 z-[200] flex items-center justify-center"
  >
    <div class="relative inline-block h-40 w-40">
      <div class="relative h-full w-full overflow-hidden">
        <AtomsLoaderIcon class="absolute bottom-0 origin-bottom" />
      </div>
    </div>
  </div>
</template>
