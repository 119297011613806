import { default as _91_46_46_46slug_93by5Qjkw00aMeta } from "/opt/build/repo/packages/global/pages/[...slug].vue?macro=true";
import { default as cartf9Ynl83tq9Meta } from "/opt/build/repo/packages/global/pages/cart.vue?macro=true";
import { default as adyenhtUHtNqXFcMeta } from "/opt/build/repo/packages/global/pages/checkout/adyen.vue?macro=true";
import { default as indexXqoYD9Otp9Meta } from "/opt/build/repo/packages/global/pages/checkout/index.vue?macro=true";
import { default as paypaleQFdlFNTriMeta } from "/opt/build/repo/packages/global/pages/checkout/paypal.vue?macro=true";
import { default as _91slug_93gpBzajXrPIMeta } from "/opt/build/repo/packages/global/pages/help/[slug].vue?macro=true";
import { default as ask_45questionDVYRqbstotMeta } from "/opt/build/repo/packages/global/pages/help/ask-question.vue?macro=true";
import { default as contact_45formAMn3r1YBQtMeta } from "/opt/build/repo/packages/global/pages/help/contact-us/contact-form.vue?macro=true";
import { default as indexBRWvVyai8kMeta } from "/opt/build/repo/packages/global/pages/help/contact-us/index.vue?macro=true";
import { default as faq93QTOg0w7uMeta } from "/opt/build/repo/packages/global/pages/help/faq.vue?macro=true";
import { default as indexAvnURmVcH8Meta } from "/opt/build/repo/packages/global/pages/help/index.vue?macro=true";
import { default as indexVYS2zH1kB2Meta } from "/opt/build/repo/packages/global/pages/index.vue?macro=true";
import { default as _91slug_93Gr9xzrlkaDMeta } from "/opt/build/repo/packages/global/pages/legal/[slug].vue?macro=true";
import { default as indexBv5hCadtQiMeta } from "/opt/build/repo/packages/global/pages/legal/index.vue?macro=true";
import { default as for_45you3RmdpXtPY7Meta } from "/opt/build/repo/packages/global/pages/my-account/for-you.vue?macro=true";
import { default as indexXZMumv5069Meta } from "/opt/build/repo/packages/global/pages/my-account/index.vue?macro=true";
import { default as indexqKiqilNPngMeta } from "/opt/build/repo/packages/global/pages/my-account/orders/[order]/index.vue?macro=true";
import { default as indexcbiP24qWpuMeta } from "/opt/build/repo/packages/global/pages/my-account/orders/[order]/return/index.vue?macro=true";
import { default as thank_45youfIpu5AE9c8Meta } from "/opt/build/repo/packages/global/pages/my-account/orders/[order]/thank-you.vue?macro=true";
import { default as indexYMkx9im9mWMeta } from "/opt/build/repo/packages/global/pages/my-account/orders/index.vue?macro=true";
import { default as addressms5QBZWt25Meta } from "/opt/build/repo/packages/global/pages/my-account/profile/address.vue?macro=true";
import { default as contactIRuw7yEpTqMeta } from "/opt/build/repo/packages/global/pages/my-account/profile/contact.vue?macro=true";
import { default as deletebi9iGANlMGMeta } from "/opt/build/repo/packages/global/pages/my-account/profile/delete.vue?macro=true";
import { default as detailsAgJw8fIcmbMeta } from "/opt/build/repo/packages/global/pages/my-account/profile/details.vue?macro=true";
import { default as index2GhH52H6YbMeta } from "/opt/build/repo/packages/global/pages/my-account/profile/index.vue?macro=true";
import { default as wishlistJXR80mHik1Meta } from "/opt/build/repo/packages/global/pages/my-account/wishlist.vue?macro=true";
import { default as previewhUgmBB4qbvMeta } from "/opt/build/repo/packages/global/pages/preview.vue?macro=true";
import { default as register_45successfulHrTfihXhC5Meta } from "/opt/build/repo/packages/global/pages/register-successful.vue?macro=true";
import { default as reset_45passwordmPgK9x30oXMeta } from "/opt/build/repo/packages/global/pages/reset-password.vue?macro=true";
import { default as cartJyB0EBz0IkMeta } from "/opt/build/repo/packages/global/pages/share/cart.vue?macro=true";
import { default as wishlistGKhFmISKGcMeta } from "/opt/build/repo/packages/global/pages/share/wishlist.vue?macro=true";
import { default as signupZU3YJdaBvmMeta } from "/opt/build/repo/packages/global/pages/signup.vue?macro=true";
import { default as _91order_93nJs6ZKmXAyMeta } from "/opt/build/repo/packages/global/pages/thank-you/[order].vue?macro=true";
import { default as indexOX0C87n71oMeta } from "/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue?macro=true";
import { default as returnx6OstX9m6SMeta } from "/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue?macro=true";
import { default as thank_45youO8x0A9bQQAMeta } from "/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue?macro=true";
import { default as ordersRSh3l6qAeJMeta } from "/opt/build/repo/packages/global/pages/track/orders.vue?macro=true";
import { default as indexRKY9lXrcCjMeta } from "/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue?macro=true";
import { default as returnsowDWTiARMGMeta } from "/opt/build/repo/packages/global/pages/track/returns.vue?macro=true";
import { default as wishlistRHdLU8c1IxMeta } from "/opt/build/repo/packages/global/pages/wishlist.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/packages/global/pages/[...slug].vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: cartf9Ynl83tq9Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/cart.vue")
  },
  {
    name: "checkout-adyen",
    path: "/checkout/adyen",
    meta: adyenhtUHtNqXFcMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/adyen.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexXqoYD9Otp9Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/index.vue")
  },
  {
    name: "checkout-paypal",
    path: "/checkout/paypal",
    meta: paypaleQFdlFNTriMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/paypal.vue")
  },
  {
    name: "help-slug",
    path: "/help/:slug()",
    meta: _91slug_93gpBzajXrPIMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/[slug].vue")
  },
  {
    name: "help-ask-question",
    path: "/help/ask-question",
    meta: ask_45questionDVYRqbstotMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/ask-question.vue")
  },
  {
    name: "help-contact-us-contact-form",
    path: "/help/contact-us/contact-form",
    component: () => import("/opt/build/repo/packages/global/pages/help/contact-us/contact-form.vue")
  },
  {
    name: "contact-us",
    path: "/help/contact-us",
    meta: indexBRWvVyai8kMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/contact-us/index.vue")
  },
  {
    name: "help-faq",
    path: "/help/faq",
    meta: faq93QTOg0w7uMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/faq.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: indexAvnURmVcH8Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/packages/global/pages/index.vue")
  },
  {
    name: "legal-slug",
    path: "/legal/:slug()",
    meta: _91slug_93Gr9xzrlkaDMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/legal/[slug].vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: indexBv5hCadtQiMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/legal/index.vue")
  },
  {
    name: "my-account-for-you",
    path: "/my-account/for-you",
    meta: for_45you3RmdpXtPY7Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/for-you.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: indexXZMumv5069Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/index.vue")
  },
  {
    name: "my-account-orders-order",
    path: "/my-account/orders/:order()",
    meta: indexqKiqilNPngMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/index.vue")
  },
  {
    name: "my-account-orders-order-return",
    path: "/my-account/orders/:order()/return",
    meta: indexcbiP24qWpuMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/return/index.vue")
  },
  {
    name: "my-account-orders-order-thank-you",
    path: "/my-account/orders/:order()/thank-you",
    meta: thank_45youfIpu5AE9c8Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/thank-you.vue")
  },
  {
    name: "my-account-orders",
    path: "/my-account/orders",
    meta: indexYMkx9im9mWMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/index.vue")
  },
  {
    name: "my-account-profile-address",
    path: "/my-account/profile/address",
    meta: addressms5QBZWt25Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/address.vue")
  },
  {
    name: "my-account-profile-contact",
    path: "/my-account/profile/contact",
    meta: contactIRuw7yEpTqMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/contact.vue")
  },
  {
    name: "my-account-profile-delete",
    path: "/my-account/profile/delete",
    meta: deletebi9iGANlMGMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/delete.vue")
  },
  {
    name: "my-account-profile-details",
    path: "/my-account/profile/details",
    meta: detailsAgJw8fIcmbMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/details.vue")
  },
  {
    name: "my-account-profile",
    path: "/my-account/profile",
    meta: index2GhH52H6YbMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/index.vue")
  },
  {
    name: "my-account-wishlist",
    path: "/my-account/wishlist",
    meta: wishlistJXR80mHik1Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/wishlist.vue")
  },
  {
    name: "preview",
    path: "/preview",
    meta: previewhUgmBB4qbvMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/preview.vue")
  },
  {
    name: "register-successful",
    path: "/register-successful",
    component: () => import("/opt/build/repo/packages/global/pages/register-successful.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordmPgK9x30oXMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/reset-password.vue")
  },
  {
    name: "share-cart",
    path: "/share/cart",
    meta: cartJyB0EBz0IkMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/share/cart.vue")
  },
  {
    name: "share-wishlist",
    path: "/share/wishlist",
    meta: wishlistGKhFmISKGcMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/share/wishlist.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupZU3YJdaBvmMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/signup.vue")
  },
  {
    name: "thank-you-order",
    path: "/thank-you/:order()",
    meta: _91order_93nJs6ZKmXAyMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/thank-you/[order].vue")
  },
  {
    name: "track-orders",
    path: "/track/orders",
    meta: ordersRSh3l6qAeJMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders.vue"),
    children: [
  {
    name: "track-orders-email-order",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "track-orders-email-order-return",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "track-orders-email-order-thank-you",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  }
]
  },
  {
    name: "track-returns",
    path: "/track/returns",
    meta: returnsowDWTiARMGMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns.vue"),
    children: [
  {
    name: "track-returns-email-return",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  }
]
  },
  {
    name: "wishlist",
    path: "/wishlist",
    meta: wishlistRHdLU8c1IxMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/wishlist.vue")
  },
  {
    name: "localized-slug",
    path: "/:locale([a-z]{2}-[a-z]{2})/:slug(.*)*",
    component: () => import("/opt/build/repo/packages/global/pages/[...slug].vue")
  },
  {
    name: "localized-cart",
    path: "/:locale([a-z]{2}-[a-z]{2})/cart",
    meta: cartf9Ynl83tq9Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/cart.vue")
  },
  {
    name: "localized-checkout-adyen",
    path: "/:locale([a-z]{2}-[a-z]{2})/checkout/adyen",
    meta: adyenhtUHtNqXFcMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/adyen.vue")
  },
  {
    name: "localized-checkout",
    path: "/:locale([a-z]{2}-[a-z]{2})/checkout",
    meta: indexXqoYD9Otp9Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/index.vue")
  },
  {
    name: "localized-checkout-paypal",
    path: "/:locale([a-z]{2}-[a-z]{2})/checkout/paypal",
    meta: paypaleQFdlFNTriMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/checkout/paypal.vue")
  },
  {
    name: "localized-help-slug",
    path: "/:locale([a-z]{2}-[a-z]{2})/help/:slug()",
    meta: _91slug_93gpBzajXrPIMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/[slug].vue")
  },
  {
    name: "localized-help-ask-question",
    path: "/:locale([a-z]{2}-[a-z]{2})/help/ask-question",
    meta: ask_45questionDVYRqbstotMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/ask-question.vue")
  },
  {
    name: "localized-help-contact-us-contact-form",
    path: "/:locale([a-z]{2}-[a-z]{2})/help/contact-us/contact-form",
    component: () => import("/opt/build/repo/packages/global/pages/help/contact-us/contact-form.vue")
  },
  {
    name: "localized-contact-us",
    path: "/:locale([a-z]{2}-[a-z]{2})/help/contact-us",
    meta: indexBRWvVyai8kMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/contact-us/index.vue")
  },
  {
    name: "localized-help-faq",
    path: "/:locale([a-z]{2}-[a-z]{2})/help/faq",
    meta: faq93QTOg0w7uMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/faq.vue")
  },
  {
    name: "localized-help",
    path: "/:locale([a-z]{2}-[a-z]{2})/help",
    meta: indexAvnURmVcH8Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/help/index.vue")
  },
  {
    name: "localized-index",
    path: "/:locale([a-z]{2}-[a-z]{2})",
    component: () => import("/opt/build/repo/packages/global/pages/index.vue")
  },
  {
    name: "localized-legal-slug",
    path: "/:locale([a-z]{2}-[a-z]{2})/legal/:slug()",
    meta: _91slug_93Gr9xzrlkaDMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/legal/[slug].vue")
  },
  {
    name: "localized-legal",
    path: "/:locale([a-z]{2}-[a-z]{2})/legal",
    meta: indexBv5hCadtQiMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/legal/index.vue")
  },
  {
    name: "localized-my-account-for-you",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/for-you",
    meta: for_45you3RmdpXtPY7Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/for-you.vue")
  },
  {
    name: "localized-my-account",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account",
    meta: indexXZMumv5069Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/index.vue")
  },
  {
    name: "localized-my-account-orders-order",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/orders/:order()",
    meta: indexqKiqilNPngMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/index.vue")
  },
  {
    name: "localized-my-account-orders-order-return",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/orders/:order()/return",
    meta: indexcbiP24qWpuMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/return/index.vue")
  },
  {
    name: "localized-my-account-orders-order-thank-you",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/orders/:order()/thank-you",
    meta: thank_45youfIpu5AE9c8Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/[order]/thank-you.vue")
  },
  {
    name: "localized-my-account-orders",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/orders",
    meta: indexYMkx9im9mWMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/orders/index.vue")
  },
  {
    name: "localized-my-account-profile-address",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/profile/address",
    meta: addressms5QBZWt25Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/address.vue")
  },
  {
    name: "localized-my-account-profile-contact",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/profile/contact",
    meta: contactIRuw7yEpTqMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/contact.vue")
  },
  {
    name: "localized-my-account-profile-delete",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/profile/delete",
    meta: deletebi9iGANlMGMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/delete.vue")
  },
  {
    name: "localized-my-account-profile-details",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/profile/details",
    meta: detailsAgJw8fIcmbMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/details.vue")
  },
  {
    name: "localized-my-account-profile",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/profile",
    meta: index2GhH52H6YbMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/profile/index.vue")
  },
  {
    name: "localized-my-account-wishlist",
    path: "/:locale([a-z]{2}-[a-z]{2})/my-account/wishlist",
    meta: wishlistJXR80mHik1Meta || {},
    component: () => import("/opt/build/repo/packages/global/pages/my-account/wishlist.vue")
  },
  {
    name: "localized-preview",
    path: "/:locale([a-z]{2}-[a-z]{2})/preview",
    meta: previewhUgmBB4qbvMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/preview.vue")
  },
  {
    name: "localized-register-successful",
    path: "/:locale([a-z]{2}-[a-z]{2})/register-successful",
    component: () => import("/opt/build/repo/packages/global/pages/register-successful.vue")
  },
  {
    name: "localized-reset-password",
    path: "/:locale([a-z]{2}-[a-z]{2})/reset-password",
    meta: reset_45passwordmPgK9x30oXMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/reset-password.vue")
  },
  {
    name: "localized-share-cart",
    path: "/:locale([a-z]{2}-[a-z]{2})/share/cart",
    meta: cartJyB0EBz0IkMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/share/cart.vue")
  },
  {
    name: "localized-share-wishlist",
    path: "/:locale([a-z]{2}-[a-z]{2})/share/wishlist",
    meta: wishlistGKhFmISKGcMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/share/wishlist.vue")
  },
  {
    name: "localized-signup",
    path: "/:locale([a-z]{2}-[a-z]{2})/signup",
    meta: signupZU3YJdaBvmMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/signup.vue")
  },
  {
    name: "localized-thank-you-order",
    path: "/:locale([a-z]{2}-[a-z]{2})/thank-you/:order()",
    meta: _91order_93nJs6ZKmXAyMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/thank-you/[order].vue")
  },
  {
    name: "localized-track-orders",
    path: "/:locale([a-z]{2}-[a-z]{2})/track/orders",
    meta: ordersRSh3l6qAeJMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders.vue"),
    children: [
  {
    name: "localized-track-orders-email-order-en-za",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ca",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-es-cl",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-us",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-es-us",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-hk",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-id",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-ja-jp",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ph",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-sg",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-ko-kr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-tw",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-th",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-tr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-de-at",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-at",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-be",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-fr-be",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-bg",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-hr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-cz",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-dk",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ee",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-fi",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-fr-fr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-fr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-de",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-de-de",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-gr",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-hu",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ie",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-it-it",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-it",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-lv",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-lt",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-lu",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-fr-lu",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-nl",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-pl",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-pt",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ro",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-sk",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-si",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-es-es",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-es",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-se",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-wx",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-au",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-nz",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-il",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-ch",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-fr-ch",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-de-ch",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-it-ch",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-en-gb",
    path: ":email()/:order()",
    meta: indexOX0C87n71oMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/index.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-za",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ca",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-es-cl",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-us",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-es-us",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-hk",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-id",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-ja-jp",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ph",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-sg",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-ko-kr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-tw",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-th",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-tr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-de-at",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-at",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-be",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-fr-be",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-bg",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-hr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-cz",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-dk",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ee",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-fi",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-fr-fr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-fr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-de",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-de-de",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-gr",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-hu",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ie",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-it-it",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-it",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-lv",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-lt",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-lu",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-fr-lu",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-nl",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-pl",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-pt",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ro",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-sk",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-si",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-es-es",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-es",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-se",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-wx",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-au",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-nz",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-il",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-ch",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-fr-ch",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-de-ch",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-it-ch",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-return-en-gb",
    path: ":email()/:order()/return",
    meta: returnx6OstX9m6SMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/return.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-za",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ca",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-es-cl",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-us",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-es-us",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-hk",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-id",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-ja-jp",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ph",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-sg",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-ko-kr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-tw",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-th",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-tr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-de-at",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-at",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-be",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-fr-be",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-bg",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-hr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-cz",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-dk",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ee",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-fi",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-fr-fr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-fr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-de",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-de-de",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-gr",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-hu",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ie",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-it-it",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-it",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-lv",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-lt",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-lu",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-fr-lu",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-nl",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-pl",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-pt",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ro",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-sk",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-si",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-es-es",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-es",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-se",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-wx",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-au",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-nz",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-il",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-ch",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-fr-ch",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-de-ch",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-it-ch",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  },
  {
    name: "localized-track-orders-email-order-thank-you-en-gb",
    path: ":email()/:order()/thank-you",
    meta: thank_45youO8x0A9bQQAMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/orders/[email]/[order]/thank-you.vue")
  }
]
  },
  {
    name: "localized-track-returns",
    path: "/:locale([a-z]{2}-[a-z]{2})/track/returns",
    meta: returnsowDWTiARMGMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns.vue"),
    children: [
  {
    name: "localized-track-returns-email-return-en-za",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ca",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-es-cl",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-us",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-es-us",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-hk",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-id",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-ja-jp",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ph",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-sg",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-ko-kr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-tw",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-th",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-tr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-de-at",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-at",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-be",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-fr-be",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-bg",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-hr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-cz",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-dk",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ee",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-fi",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-fr-fr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-fr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-de",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-de-de",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-gr",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-hu",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ie",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-it-it",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-it",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-lv",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-lt",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-lu",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-fr-lu",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-nl",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-pl",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-pt",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ro",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-sk",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-si",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-es-es",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-es",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-se",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-wx",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-au",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-nz",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-il",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-ch",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-fr-ch",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-de-ch",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-it-ch",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  },
  {
    name: "localized-track-returns-email-return-en-gb",
    path: ":email()/:return()",
    meta: indexRKY9lXrcCjMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/track/returns/[email]/[return]/index.vue")
  }
]
  },
  {
    name: "localized-wishlist",
    path: "/:locale([a-z]{2}-[a-z]{2})/wishlist",
    meta: wishlistRHdLU8c1IxMeta || {},
    component: () => import("/opt/build/repo/packages/global/pages/wishlist.vue")
  }
]