import type { ReCaptchaInstance } from 'recaptcha-v3'

interface IGlobalConfig {
  loadedWaiters: Array<{
    resolve: (resolve: boolean) => void
    reject: (reject: Error) => void
  }>
  error: Error | null
}
const globalConfig: IGlobalConfig = {
  loadedWaiters: [],
  error: null,
}

const useRecaptchaLoaded = () => useState('is-recaptcha-loaded', () => false)
const useRecaptchaInstance = () =>
  useState<ReCaptchaInstance | null>('recaptcha-instance', () => null)

const useRecaptchaLoader = async () => {
  const config = useRuntimeConfig()
  const isLoaded = useRecaptchaLoaded()
  const instance = useRecaptchaInstance()
  if (isLoaded.value) return instance
  const { load } = await import('recaptcha-v3')
  const _instance = await load(config.public.recaptcha.key, {
    autoHideBadge: true,
    useEnterprise: true,
  })
  isLoaded.value = true
  instance.value = _instance
  return instance
}

export const useReCaptcha = async () => {
  const instance = await useRecaptchaLoader()
  const isLoaded = useRecaptchaLoaded()

  return {
    instance,
    isLoaded: isLoaded.value,
    executeRecaptcha: recaptcha(instance),
    recaptchaLoaded: recaptchaLoaded(isLoaded),
  }
}

export const useRecaptchaSite = () => {
  // @ts-ignore
  const { addSnackbar } = useHeaderSnackbars()

  const getRecaptchaToken = async (action: string) => {
    const { executeRecaptcha } = await useReCaptcha()

    const token = await executeRecaptcha(action)

    return token
  }
  const verifyReCaptchaToken = async (token: string) => {
    const response = await $fetch(`/api/recaptcha/validateRecaptcha/${token}`, {
      method: 'GET',
    })
    const value = ref(response)
    return {
      data: value,
    }
  }
  type ResultsReCaptcha = {
    resultToken: boolean
    resultVerifyReCaptchaToken: boolean
  }
  const checkTokenAndVerify = async (
    action: string
  ): Promise<ResultsReCaptcha> => {
    const token = await getRecaptchaToken(action)
    const verifyToken = token ? await verifyReCaptchaToken(token) : null
    if (!token) {
      return {
        resultToken: false,
        resultVerifyReCaptchaToken: false,
      }
    } else if (
      verifyToken &&
      (verifyToken.data.value as any).response.success
    ) {
      return {
        resultToken: true,
        resultVerifyReCaptchaToken: true,
      }
    } else {
      return {
        resultToken: false,
        resultVerifyReCaptchaToken: false,
      }
    }
  }
  const checkTokenReCAPTCHA = async (message: string) => {
    const check = await checkTokenAndVerify(message)
    if (!check.resultToken || !check.resultVerifyReCaptchaToken) {
      addSnackbar(message, {
        message: {
          key: 'generic.recaptchaError',
        },
        duration: 5,
        textClasses: 'text-primitives-red',
      })
      throw createError({
        statusCode: 422,
        statusMessage: `[ERROR]: reCAPTHCA failed on form: ${message}`,
      })
    }
    return true
  }
  return {
    getRecaptchaToken,
    verifyReCaptchaToken,
    checkTokenAndVerify,
    checkTokenReCAPTCHA,
  }
}

function recaptchaLoaded(isLoaded: Ref<boolean>) {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  return () =>
    new Promise<boolean>((resolve, reject) => {
      if (globalConfig.error !== null) {
        return reject(globalConfig.error)
      }
      if (isLoaded.value) {
        return resolve(true)
      }
      globalConfig.loadedWaiters.push({ resolve, reject })
    })
}

function recaptcha(instance: Ref<ReCaptchaInstance | null>) {
  return async (action: string): Promise<string | undefined> => {
    return await instance.value?.execute(action)
  }
}
