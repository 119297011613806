import type { UtilsSnackbarProps } from '@design-system/components/Utils/UtilsSnackbar.props'
export type SnackbarMessage = {
  message: { key: string; named?: Record<string, string | number> }
  actionMessage?: string
  duration?: number
  textClasses?: string
}

export const useHeaderSnackbarMessage = () => {
  const { ts } = useI18n()
  const getSnackBarProps = (m: SnackbarMessage): UtilsSnackbarProps => {
    return {
      message: m.message.named
        ? ts(m.message.key, m.message.named)
        : ts(m.message.key),
      actionMessage: m.actionMessage,
      duration: m.duration,
      textClasses: m.textClasses,
    }
  }
  return { getSnackBarProps }
}

export const useHeaderSnackbars = () => {
  const headerSnackbars = useState(
    () =>
      new Map<
        string,
        {
          data: SnackbarMessage
          timeout?: ReturnType<typeof useTimeoutFn>
        }
      >()
  )

  const addSnackbar = (id: string, data: SnackbarMessage) => {
    headerSnackbars.value.set(id, {
      data,
      timeout:
        data.duration && data.duration > 0
          ? useTimeoutFn(() => {
              if (headerSnackbars.value.has(id)) {
                headerSnackbars.value.delete(id)
              }
            }, data.duration * 1000)
          : undefined,
    })
  }

  const deleteSnackbar = (id: string) => {
    if (headerSnackbars.value.has(id)) {
      headerSnackbars.value.get(id)?.timeout?.stop()
      headerSnackbars.value.delete(id)
    }
  }
  const clearAllSnackbars = () => {
    headerSnackbars.value.clear()
  }

  return {
    headerSnackbars,
    addSnackbar,
    deleteSnackbar,
    clearAllSnackbars,
  }
}
