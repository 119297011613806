import type { Address } from '@commercelayer/sdk'

export const getSkuWithoutSize = (sku: string | null | undefined) => {
  const splitSku = sku?.split('_')
  return Array.isArray(splitSku) && splitSku.length > 3
    ? splitSku.slice(0, 3).join('_')
    : sku
}

export const splitSizeIntoSizeAndCountry = (sizeWithCountry?: string) => {
  const match = sizeWithCountry?.match(/^([A-Za-z0-9]+) \(([^)]+)\)$/)
  return {
    size: match?.[1] ?? '',
    country: match?.[2] ?? '',
  }
}

export const isPlaceholderAddress = (address?: Address | null) =>
  address?.full_name === 'placeholder placeholder' &&
  address?.line_1 === 'placeholder'
