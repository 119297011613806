import { z } from 'zod'

export type Views = z.infer<typeof views>

export const views = z.enum([
  'cart_page',
  'mini_cart',
  'wishlist_page',
  'quick_product_popup',
  'product_page',
  'product_listing_page',
])

export const binaryChoiceSchema = z.literal(0).or(z.literal(1))

export const ecommerceSchema = z.object({
  client_service: binaryChoiceSchema,
  currency: z.string(),
  value: z.number(),
})

export const itemSchema = z.object({
  item_id: z.string(),
  item_name: z.string(),
  affiliation: z.string(),
  coupon: z.string(),
  discount: z.number(),
  index: z.number(),
  item_brand: z.string(),
  item_category: z.string(),
  item_category2: z.string(),
  item_category4: z.string(),
  item_list_id: z.string(),
  item_list_name: z.string(),
  item_variant: z.string(),
  price: z.number(),
  quantity: z.number(),
  item_availability: binaryChoiceSchema,
  item_size: z.string(),
  item_reservable: binaryChoiceSchema,
  item_discount: binaryChoiceSchema,
  item_mfc_id: z.string(),
  item_img: z.string(),
})
