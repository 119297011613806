import { authenticate } from '@commercelayer/js-auth'
import type { Serialize } from 'nitropack'

export const getHost = (organization: string) =>
  `https://${organization}.commercelayer.io`

export const getScope = (marketCode: string) => `market:code:${marketCode}`

export const fetchGuestToken = async (clientId: string, marketCode: string) =>
  authenticate('client_credentials', {
    clientId,
    scope: getScope(marketCode),
  })

export type Token = Awaited<ReturnType<typeof fetchGuestToken>>
export type SerializedToken = Serialize<Token>

export const fetchIntegration = async (
  clientId: string,
  clientSecret: string
) =>
  authenticate('client_credentials', {
    clientId,
    clientSecret,
  })

export const isTokenValid = (token: Token | SerializedToken) => {
  if (token.errors?.length) return false

  const expires =
    typeof token.expires === 'string' ? new Date(token.expires) : token.expires

  return Date.now() < expires.getTime()
}
